/** @prettier */
/* eslint-disable import/prefer-default-export */
import appActions from 'redux/app/actions';
import {
  AAPLY_FILTERS_TO_USAGE,
  FETCH_MONTH_USAGE,
  FETCH_MONTH_USAGE_FAILURE,
  FETCH_MONTH_USAGE_ISLOADING,
  FETCH_MONTH_USAGE_SUCCESS,
  FETCH_PAST_MONTHS_USAGE,
  FETCH_PAST_MONTHS_USAGE_FAILURE,
  FETCH_PAST_MONTHS_USAGE_SUCCESS,
  FETCH_USAGE_MONTHS,
  FETCH_USAGE_MONTHS_FAILURE,
  FETCH_USAGE_MONTHS_ISLOADING,
  FETCH_USAGE_MONTHS_SUCCESS,
  SET_SELECTED_DATE,
  UPDATE_SUBSCRIPTION,
  UPDATE_EMAIL_TOGGLE,
  USAGE_TRANSCRIPT_GRAPH,
  USAGE_BILLING_DATA
} from './constants';

export const fetchUsageMonths = (search = null) => ({
  type: FETCH_USAGE_MONTHS,
  search,
});

export const fetchUsageMonthsIsLoading = (isLoading) => ({
  type: FETCH_USAGE_MONTHS_ISLOADING,
  isLoading,
});

export const fetchUsageMonthsSuccess = (data) => ({
  type: FETCH_USAGE_MONTHS_SUCCESS,
  data,
});

export const fetchUsageMonthsFailure = (err) => ({
  type: FETCH_USAGE_MONTHS_FAILURE,
  err,
});

export const applyFiltersToUsage = (filter) => ({
  type: AAPLY_FILTERS_TO_USAGE,
  filter,
});

export const fetchMonthUsage = (monthCode, past = false) => ({
  type: FETCH_MONTH_USAGE,
  monthCode,
  past,
});

export const fetchMonthUsageIsLoading = (data) => ({
  type: FETCH_MONTH_USAGE_ISLOADING,
  data,
});

export const fetchMonthUsageSuccess = (data) => ({
  type: FETCH_MONTH_USAGE_SUCCESS,
  data,
});

export const fetchMonthUsageFailure = (err) => ({
  type: FETCH_MONTH_USAGE_FAILURE,
  err,
});

export const setSelectedDate = (monthCode) => ({
  type: SET_SELECTED_DATE,
  monthCode,
});

export const fetchPastMonthsUsage = (value) => ({
  type: FETCH_PAST_MONTHS_USAGE,
  value,
});

export const fetchPastMonthsUsageSuccess = (value) => ({
  type: FETCH_PAST_MONTHS_USAGE_SUCCESS,
  value,
});

export const fetchPastMonthsUsageFailure = (value) => ({
  type: FETCH_PAST_MONTHS_USAGE_FAILURE,
  value,
});

//  saga for this type is defined in redux/app
export const changeActiveAccountById = (activeAccountId) => ({
  type: appActions.CHANGE_ACTIVE_ACCOUNT_BY_ID,
  activeAccountId,
});

export const updateSubscription = (account) => ({
  type: UPDATE_SUBSCRIPTION,
  data: { account },
  id: account.id,
});

export const updateActiveAccount = (account) => ({
  type: appActions.CHANGE_ACTIVE_ACCOUNT,
  activeAccountId: account.id,
  activeAccount: account,
  clear: true,
});

export const updateEmailToggle = (value) => ({
  type: UPDATE_EMAIL_TOGGLE,
  value,
});

export const fetchUsageTranscriptGraph = (monthCode) => ({
  type: USAGE_TRANSCRIPT_GRAPH,
  monthCode,
});

export const fetchUsageBillingData = (monthCode) => ({
  type: USAGE_BILLING_DATA,
  monthCode,
});