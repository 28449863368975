/* istanbul ignore file */
import { forEach } from 'lodash';
import SchemaData from 'schema/media-configuration/schema.json';

export const CONNECTION_TABS = {
  CONNECTION: 'CONNECTION',
  UPLOAD: 'UPLOAD',
  API: 'API',
};

const getLanguageLabel = (languageDetail) => {
  if (languageDetail.Queen) {
    return languageDetail.Queen.description;
  } if (languageDetail.King) {
    return languageDetail.King.description;
  }
  if (languageDetail.Pawn) {
    return languageDetail.Pawn.description;
  }
  return null;
};

export const languages = [];
forEach(SchemaData, (languageDetail, key) => {
  languages.push({
    name: key,
    label: getLanguageLabel(languageDetail),
    details: languageDetail,
  });
});

export const speechModalOptions = [
  {
    name: 'King',
    label: 'Titan',
  },
  {
    name: 'Queen',
    label: 'Europa',
  },
  {
    name: 'Bishop',
    label: 'Proteus',
  },
  {
    name: 'Pawn',
    label: 'Phobos',
  },
];

export const uploadWizardOptions = {
  defaultListCount: 3,
};
