/** @prettier */
import React, { useEffect, Suspense } from 'react';
import { connect } from 'react-redux';
import { Layout, ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { useOktaAuth } from '@okta/okta-react';
import authAction from 'redux/auth/actions';
import themes from 'settings/themes';
import appActions from 'redux/app/actions';
import NotFound from 'components-shared/table/NotFound';
import userActions from 'redux/users/actions';
import { getStartingTasks } from 'redux/users/selectors';
import { withLocalStorage } from 'hoc/withLocalStorage';
import Meta from '../Meta';
import UserRestrictedPopup from './UserRestrictedPopup';
import Loading from '../uielements/Loading';
import './global.css';
import MainStyle from './styles';

const MainContent = React.lazy(() => import('./MainContent'));

const Main = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = authState;

  const {
    auth,
    history,
    setHistory: setHistoryAction,
    loginOktaSuccess,
    logout,
    activeAccount,
    removeItem,
    userRestricted,
    whiteListedAccounts,
    setUserRestricted,
    fetchMyUser,
    hasAdminPrivileges,
  } = props;
  useEffect(() => {
    setHistoryAction(history);
    ['dpBlurredDim', 'dpBlurredMeasures', 'blurredSelects'].forEach((key) => removeItem(key));
  }, [history, removeItem, setHistoryAction]);

  useEffect(() => {
    async function checkAuthentication() {
      const { token } = auth;
      const { accessToken } = authState;
      if (isAuthenticated && (!token || accessToken.accessToken !== token)) {
        try {
          const userinfo = await oktaAuth.token.getUserInfo();
          if (!userinfo) {
            logout();
            return;
          }
          loginOktaSuccess({ userinfo, token: accessToken.accessToken });
        } catch (e) {
          history?.push('/logout');
        }
      }
    }
    checkAuthentication();
  }, [authState, oktaAuth, isAuthenticated, loginOktaSuccess, logout, auth, history]);

  return (
    <ConfigProvider renderEmpty={NotFound}>
      <Meta />
      <ThemeProvider theme={themes.dark}>
        <MainStyle>
          <Layout style={{ height: '100%' }}>
            {userRestricted && (
              <UserRestrictedPopup
                history={history}
                whiteListedAccounts={whiteListedAccounts}
                setUserRestricted={setUserRestricted}
                fetchMyUser={fetchMyUser}
                hasAdminPrivileges={hasAdminPrivileges}
              />
            )}
            <Suspense fallback={<Loading />}>{activeAccount?.id && <MainContent {...props} />}</Suspense>
          </Layout>
        </MainStyle>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default connect(
  (state) => ({
    apiConnected: state.App.apiConnected,
    auth: state.Auth,
    activeAccount: state.App.activeAccount,
    userRoles: state.App.activeAccount.roles,
    tokenRoles: state.Profile.groups,
    openDrawer: state.App.openDrawer,
    products: state.App.activeAccount.products,
    hasAdminPrivileges: state.Profile.hasAdminPrivileges,
    startingTasks: getStartingTasks(state.Profile),
    preferences: state.Profile.user?.preferences,
    user: state.Profile.user,
    userRestricted: state.App.userRestricted,
    whiteListedAccounts: state.App.whiteListedAccounts,
    activeAccountBulkDownloadFlag: state.App.activeAccountBulkDownloadFlag
  }),
  {
    logout: authAction.logout,
    loginOktaSuccess: authAction.loginOktaSuccess,
    setHistory: appActions.setHistory,
    toggleOpenDrawer: appActions.toggleOpenDrawer,
    editUser: userActions.editUser,
    setUserRestricted: appActions.setUserRestricted,
    fetchMyUser: userActions.fetchMyUser,
  }
)(withLocalStorage(Main));
