import { SET_SELECTED_COLUMNS } from './actions';

const initialState = {
  selectedColumns: {},
};

const columnsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COLUMNS:
      const { tableId, selectedColumns } = action.payload;
      return {
        ...state,
        selectedColumns: {
          ...state.selectedColumns,
          [tableId]: selectedColumns,
        },
      };
    default:
      return state;
  }
};

export default columnsReducer;
