/** @prettier */
/* eslint-disable linebreak-style */
const accountActions = {
  FETCH_ACCOUNTS: 'FETCH_ACCOUNTS',
  FETCH_ACCOUNTS_SUCCESS: 'FETCH_ACCOUNTS_SUCCESS',
  FETCH_ACCOUNTS_FAILURE: 'FETCH_ACCOUNTS_FAILURE',
  FILTER_ACCOUNTS: 'FILTER_ACCOUNTS',
  FILTER_ACCOUNTS_SUCCESS: 'FILTER_ACCOUNTS_SUCCESS',
  SELECT_ACCOUNT: 'SELECT_ACCOUNT',
  SELECT_ACCOUNT_SUCCESS: 'SELECT_ACCOUNT_SUCCESS',
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  ADD_ACCOUNT_SUCCESS: 'ADD_ACCOUNT_SUCCESS',
  EDIT_ACCOUNT: 'EDIT_ACCOUNT',
  EDIT_ACCOUNT_SUCCESS: 'EDIT_ACCOUNT_SUCCESS',
  EDIT_ACCOUNT_FAILURE: 'EDIT_ACCOUNT_FAILURE',
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  FETCH_ACCOUNT_SUCCESS: 'FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAILURE: 'FETCH_ACCOUNT_FAILURE',
  FETCH_ACCOUNT_OPTIONS: 'FETCH_ACCOUNT_OPTIONS',
  FETCH_ACCOUNT_OPTIONS_SUCCESS: 'FETCH_ACCOUNT_OPTIONS_SUCCESS',
  FETCH_MY_ACCOUNT: 'FETCH_MY_ACCOUNT',
  EDIT_MY_ACCOUNT: 'EDIT_MY_ACCOUNT',
  EDIT_MY_ACCOUNT_SUCCESS: 'EDIT_MY_ACCOUNT_SUCCESS',
  EDIT_MY_ACCOUNT_FAILURE: 'EDIT_MY_ACCOUNT_FAILURE',
  UPDATE_VB_ACCESS: 'UPDATE_VB_ACCESS',
  DELETE__ACCOUNT: 'DELETE__ACCOUNT',
  CHANGE_ACCOUNT: 'CHANGE_ACCOUNT',
  CHANGE_ACCOUNT_VIEW: 'CHANGE_ACCOUNT_VIEW',
  REFRESH_ACCOUNT_SUCCESS: 'REFRESH_ACCOUNT_SUCCESS',
  REFRESH_SELECTED_ACCOUNT: 'REFRESH_SELECTED_ACCOUNT',
  CLEAR_ACCOUNTS: 'CLEAR_ACCOUNTS',
  SORT_ACCOUNTS: 'SORT_ACCOUNTS',
  FETCH_TTL: 'FETCH_TTL',
  FETCH_TTL_SUCCESS: 'FETCH_TTL_SUCCESS',
  FETCH_TTL_FAILURE: 'FETCH_TTL_FAILURE',
  UPDATE_TTL: 'UPDATE_TTL',
  UPDATE_TTL_SUCCESS: 'UPDATE_TTL_SUCCESS',
  UPDATE_TTL_FAILURE: 'UPDATE_TTL_FAILURE',
  FETCH_ACCOUNT_ETL_CONFIG: 'FETCH_ACCOUNT_ETL_CONFIG',
  FETCH_ACCOUNT_ETL_CONFIG_SUCCESS: 'FETCH_ACCOUNT_ETL_CONFIG_SUCCESS',
  SET_ACCESS_ACCOUNTS: 'SET_ACCESS_ACCOUNTS',
  SET_ACCESS_ACCOUNTS_SUCCESS: 'SET_ACCESS_ACCOUNTS_SUCCESS',
  SHOW_TTL_FORM: 'SHOW_TTL_FORM',
  FETCH_DEFAULT_SPEECH_MODEL: 'FETCH_DEFAULT_SPEECH_MODEL',
  FETCH_DEFAULT_SPEECH_MODEL_SUCCESS: 'FETCH_DEFAULT_SPEECH_MODEL_SUCCESS',
  FETCH_DEFAULT_SPEECH_MODEL_FAILURE: 'FETCH_DEFAULT_SPEECH_MODEL_FAILURE',
  UPDATE_DEFAULT_SPEECH_MODEL: 'UPDATE_DEFAULT_SPEECH_MODEL',
  UPDATE_DEFAULT_SPEECH_MODEL_SUCCESS: 'UPDATE_DEFAULT_SPEECH_MODEL_SUCCESS',
  UPDATE_DEFAULT_SPEECH_MODEL_FAILURE: 'UPDATE_DEFAULT_SPEECH_MODEL_FAILURE',
  SHOW_DEFAULT_SPEECH_DIALOG: 'SHOW_DEFAULT_SPEECH_DIALOG',
  CHANGE_ACCOUNT_VIEW_FOR_IMPORT: 'CHANGE_ACCOUNT_VIEW_FOR_IMPORT',
  FETCH_PLATFORM_ACCOUNT: 'FETCH_PLATFORM_ACCOUNT',
  FETCH_PLATFORM_ACCOUNT_SUCCESS: 'FETCH_PLATFORM_ACCOUNT_SUCCESS',
  FETCH_PLATFORM_ACCOUNT_FAILURE: 'FETCH_PLATFORM_ACCOUNT_FAILURE',
  UPDATE_SPEECH_DEFAUT_LANGUAGE: 'UPDATE_SPEECH_DEFAUT_LANGUAGE',
  UPDATE_SPEECH_DEFAUT_LANGUAGE_SUCCESS: 'UPDATE_SPEECH_DEFAUT_LANGUAGE_SUCCESS',
  UPDATE_SPEECH_DEFAUT_LANGUAGE_FAILURE: 'UPDATE_SPEECH_DEFAUT_LANGUAGE_FAILURE',
  GET_SPEECH_DEFAULT_LANGUAGE: 'GET_SPEECH_DEFAULT_LANGUAGE',
  GET_SPEECH_DEFAULT_LANGUAGE_SUCCESS: 'GET_SPEECH_DEFAULT_LANGUAGE_SUCCESS',
  GET_SPEECH_DEFAULT_LANGUAGE_FAILURE: 'GET_SPEECH_DEFAULT_LANGUAGE_FAILURE',
  CLEAR_DEFAULT_LANGS_MODEL: 'CLEAR_DEFAULT_LANGS_MODEL',
  GET_PREDICTIVE_MODEL: 'GET_PREDICTIVE_MODEL',
  GET_PREDICTIVE_MODEL_SUCCESS: 'GET_PREDICTIVE_MODEL_SUCCESS',
  GET_PREDICTIVE_MODEL_FAILURE: 'GET_PREDICTIVE_MODEL_FAILURE',
  FETCH_DEFAULT_LANGUAGE_MODEL: 'FETCH_DEFAULT_LANGUAGE_MODEL',
  CLEAR_SELECTED_ITEM: 'CLEAR_SELECTED_ITEM',
  ETL_NOTIFICATION_DIALOG: 'ETL_NOTIFICATION_DIALOG',
  CHANGE_ACCOUNT_FOCUS_TAB: 'CHANGE_ACCOUNT_FOCUS_TAB',
  VALIDATE_CONNECTION: 'VALIDATE_CONNECTION',
  VALIDATE_CONNECTION_SUCCESS: 'VALIDATE_CONNECTION_SUCCESS',
  VALIDATE_CONNECTION_FAILURE: 'VALIDATE_CONNECTION_FAILURE',
  FETCH_MISSING_RECORDS: 'FETCH_MISSING_RECORDS',
  FETCH_MISSING_RECORDS_SUCCESS: 'FETCH_MISSING_RECORDS_SUCCESS',
  FETCH_MISSING_RECORDS_FAILURE: 'FETCH_MISSING_RECORDS_FAILURE',
  FETCH_SKIPPED_RECORDS: 'FETCH_SKIPPED_RECORDS',
  FETCH_SKIPPED_RECORDS_SUCCESS: 'FETCH_SKIPPED_RECORDS_SUCCESS',
  FETCH_SKIPPED_RECORDS_FAILURE: 'FETCH_SKIPPED_RECORDS_FAILURE',
  TOGGLE_ACCOUNT_IS_LOADING: 'TOGGLE_ACCOUNT_IS_LOADING',
  CHANGE_ACCOUNT_FORM_VIEW: 'CHANGE_ACCOUNT_FORM_VIEW',
  FETCH_CATEGORIZATION_JOBS: 'FETCH_CATEGORIZATION_JOBS',
  FETCH_CATEGORIZATION_JOBS_SUCCESS: 'FETCH_CATEGORIZATION_JOBS_SUCCESS',
  FETCH_CATEGORIZATION_JOBS_FAILURE: 'FETCH_CATEGORIZATION_JOBS_FAILURE',
  INITIATE_DELETE_PROCESS: "INITIATE_DELETE_PROCESS",
  FETCH_ALL_CLEANUPS: "FETCH_ALL_CLEANUPS",
  FETCH_ALL_CLEANUPS_SUCCESS: "FETCH_ALL_CLEANUPS_SUCCESS",
  APPROVE_CLEANUP: "APPROVE_CLEANUP",
  REJECT_CLEANUP: "REJECT_CLEANUP",
  REJECT_CLEANUP: "REJECT_CLEANUP",
  GET_DELETE_REQUESTS_PLATFORM: 'GET_DELETE_REQUESTS_PLATFORM',
  FETCH_EMBEDED_DASHBOARD_URL: 'FETCH_EMBEDED_DASHBOARD_URL',
  FETCH_EMBEDED_DASHBOARD_URL_SUCCESS: 'FETCH_EMBEDED_DASHBOARD_URL_SUCCESS',
  UPDATE_EMBEDED_DASHBOARD_URL: 'UPDATE_EMBEDED_DASHBOARD_URL',
  FETCH_BULK_DOWNLOAD_CONFIG_SETING: 'FETCH_BULK_DOWNLOAD_CONFIG_SETING',
  FETCH_BULK_DOWNLOAD_CONFIG_SETING_SUCCESS:'FETCH_BULK_DOWNLOAD_CONFIG_SETING_SUCCESS',
  UPDATE_BULK_DOWNLOAD_CONFIG_SETING:'UPDATE_BULK_DOWNLOAD_CONFIG_SETING',
  UPDATE_BULK_DOWNLOAD_CONFIG_SETING_SUCCESS:'UPDATE_BULK_DOWNLOAD_CONFIG_SETING_SUCCESS',
  RESET_BULK_DOWNLOAD_STATUS: 'RESET_BULK_DOWNLOAD_STATUS',

  fetchCategorizationJobs: () => ({
    type: accountActions.FETCH_CATEGORIZATION_JOBS,
  }),

  sortAccounts: (e) => ({
    type: accountActions.SORT_ACCOUNTS,
    sort: e.sort,
  }),
  clearAccounts: () => ({
    type: accountActions.CLEAR_ACCOUNTS,
  }),
  fetchAccounts: () => ({
    type: accountActions.FETCH_ACCOUNTS,
  }),
  filterAccounts: (filters) => ({
    type: accountActions.FILTER_ACCOUNTS,
    filters,
  }),
  selectAccount: (selectedItem) => ({
    type: accountActions.SELECT_ACCOUNT,
    selectedItem,
  }),
  addAccount: (data, onFinish) => ({
    type: accountActions.ADD_ACCOUNT,
    data,
    onFinish,
  }),
  editAccount: (data, id, onFinish = () => {}, updateActiveAccount = false, isMyAccount = false) => ({
    type: accountActions.EDIT_ACCOUNT,
    data,
    id,
    onFinish,
    updateActiveAccount,
    isMyAccount,
  }),
  editMyAccount: (data, id, onFinish = () => {}) => ({
    type: accountActions.EDIT_MY_ACCOUNT,
    data,
    id,
    onFinish,
  }),
  fetchAccount: (id) => ({
    type: accountActions.FETCH_ACCOUNT,
    id,
  }),
  updateVbAccess: (access, id) => ({
    type: accountActions.UPDATE_VB_ACCESS,
    access,
    id,
  }),
  deleteAccount: (id) => ({
    type: accountActions.DELETE__ACCOUNT,
    id,
  }),
  refreshSelectedAccount: () => ({
    type: accountActions.REFRESH_SELECTED_ACCOUNT,
  }),
  changeAccountView: (selectedItem, focusOnTab) => ({
    type: accountActions.CHANGE_ACCOUNT_VIEW,
    selectedItem,
    focusOnTab,
  }),
  changeAccountFocusTab: (focusOnTab) => ({
    type: accountActions.CHANGE_ACCOUNT_FOCUS_TAB,
    focusOnTab,
  }),
  fetchTTL: (accountID) => ({
    type: accountActions.FETCH_TTL,
    accountID,
  }),
  fetchTTLSuccess: (data) => ({
    type: accountActions.FETCH_TTL_SUCCESS,
    data,
  }),
  fetchTTLFailure: (err) => ({
    type: accountActions.FETCH_TTL_FAILURE,
    err,
  }),
  updateTTL: (data) => ({
    type: accountActions.UPDATE_TTL,
    data,
  }),
  updateTTLSuccess: (data) => ({
    type: accountActions.UPDATE_TTL_SUCCESS,
    data,
  }),
  updateTTLFailure: (err) => ({
    type: accountActions.UPDATE_TTL_FAILURE,
    err,
  }),
  toggleTTLForm: () => ({
    type: accountActions.SHOW_TTL_FORM,
  }),
  fetchDefaultSpeechModel: (accountID) => ({
    type: accountActions.FETCH_DEFAULT_SPEECH_MODEL,
    accountID,
  }),
  fetchDefaultSpeechModelSuccess: (data) => ({
    type: accountActions.FETCH_DEFAULT_SPEECH_MODEL_SUCCESS,
    data,
  }),
  fetchDefaultSpeechModelFailure: (err) => ({
    type: accountActions.FETCH_DEFAULT_SPEECH_MODEL_FAILURE,
    err,
  }),
  updateDefaultSpeechModel: (data, accountID) => ({
    type: accountActions.UPDATE_DEFAULT_SPEECH_MODEL,
    data,
    accountID,
  }),
  updateDefaultSpeechModelSuccess: (data) => ({
    type: accountActions.UPDATE_DEFAULT_SPEECH_MODEL_SUCCESS,
    data,
  }),
  updateDefaultSpeechModelFailure: (err) => ({
    type: accountActions.UPDATE_DEFAULT_SPEECH_MODEL_FAILURE,
    err,
  }),
  toggleDefaultSpeechModalForm: (add = false) => ({
    type: accountActions.SHOW_DEFAULT_SPEECH_DIALOG,
    add,
  }),
  changeAccountViewForImport: () => ({
    type: accountActions.CHANGE_ACCOUNT_VIEW_FOR_IMPORT,
  }),
  fetchPlatformAccount: (id) => ({
    type: accountActions.FETCH_PLATFORM_ACCOUNT,
    id,
  }),
  updateDefaultSpeechLanguage: (data, selectedItem, totalLength, currentIndex) => ({
    type: accountActions.UPDATE_SPEECH_DEFAUT_LANGUAGE,
    data,
    selectedItem,
    totalLength,
    currentIndex,
  }),
  getDefaultSpeechLanguageSaga: (lng, accountID, currentIndex) => ({
    type: accountActions.GET_SPEECH_DEFAULT_LANGUAGE,
    lng,
    accountID,
    currentIndex,
  }),
  getDefaultSpeechLanguageSagaSuccess: (data) => ({
    type: accountActions.GET_SPEECH_DEFAULT_LANGUAGE_SUCCESS,
    data,
  }),
  clearDefaultLangsModel: () => ({
    type: accountActions.CLEAR_DEFAULT_LANGS_MODEL,
  }),
  getPredictiveModel: (accountID) => ({
    type: accountActions.GET_PREDICTIVE_MODEL,
    accountID,
  }),
  clearSelectedItem: () => ({
    type: accountActions.CLEAR_SELECTED_ITEM,
  }),
  fetchEtlConfig: (selectedItem, isMyAccount) => ({
    type: accountActions.FETCH_ACCOUNT_ETL_CONFIG,
    selectedItem,
    isMyAccount,
  }),
  toggleEtlNotification: (data) => ({
    type: accountActions.ETL_NOTIFICATION_DIALOG,
    data,
  }),
  validateConnection: (data, onFinish) => ({
    type: accountActions.VALIDATE_CONNECTION,
    data,
    onFinish,
  }),
  fetchMissingRecords: (dateRange, accountId) => ({
    type: accountActions.FETCH_MISSING_RECORDS,
    dateRange,
    accountId,
  }),
  fetchSkippedRecords: (dateRange, accountId) => ({
    type: accountActions.FETCH_SKIPPED_RECORDS,
    dateRange,
    accountId,
  }),
  updateEmbededDashboardUrl: (accountId, data, recordId) => (
    {
    type: accountActions.UPDATE_EMBEDED_DASHBOARD_URL,
    accountId,
    data,
    recordId
  }),
  initiateDeleteProcess: (accountId,email,cb) => ({
    type: accountActions.INITIATE_DELETE_PROCESS,
    accountId,
    email,
    cb
  }),
  fetchAllCleanups: () => ({
    type: accountActions.FETCH_ALL_CLEANUPS
  }),
  approveCleanup: (id, data, cb) => ({
    type: accountActions.APPROVE_CLEANUP,
    id,
    data,
    cb
  }),
  rejectCleanup: (id,cb ) => ({
    type: accountActions.REJECT_CLEANUP,
    id,
    cb
  }),
  fetchAllCleanupsPlatform: () => ({
    type: accountActions.GET_DELETE_REQUESTS_PLATFORM
  }),
  getEmbededDashboardUrl: (accountId) => (
   {
    type: accountActions.FETCH_EMBEDED_DASHBOARD_URL,
    accountId
  }),
  getBulkDownloadConfigSeting: (accountId) => ({
    type: accountActions.FETCH_BULK_DOWNLOAD_CONFIG_SETING,
    accountId
  }),
  updateBulkDownloadConfigSeting: (accountId,data,isActiveAccountSelected, cb) => ({
    type: accountActions.UPDATE_BULK_DOWNLOAD_CONFIG_SETING,
    accountId,
    data,
    isActiveAccountSelected,
    cb
  }),
  resetBulkDownloadStatus : () => ({
    type: accountActions.RESET_BULK_DOWNLOAD_STATUS
  })
};
export default accountActions;
