/** @prettier */
import * as Yup from 'yup';
import { keyBy } from 'lodash';
import { isValidArnRoleString, isValidAzureBlobServiceWithContainerURL, isValidAzureQueueURL } from 'utils';
import moment from 'moment';
import validations from './validations';

const connectionVendors = {
  label: 'Vendor',
  value: 'vendor',
  defaultValue: 'CUSTOM',
  required: true,
  vendor: [
    {
      label: 'AWS S3',
      value: 'CUSTOM',
      env: null,
    },
    {
      label: 'AZURE',
      value: 'AZUREINGRESS',
      env: 'REACT_APP_CONNECTION_AZURE',
    },
    {
      label: 'LivePerson Messaging',
      value: 'LIVEPERSON',
      env: null,
    },
    {
      label: 'LivePerson Chat',
      value: 'LIVEPERSONLIVECHAT',
      env: null,
    },
    {
      label: 'LivePerson Agent Status',
      value: 'LIVEPERSONAGENTSTATUS',
      env: 'REACT_APP_CONNECTION_AGENT_STATUS',
    },
  ],
};

const connectionModel = [
  {
    field: 'id',
    primaryKey: true,
    server: true,
    allowedVendors: ['all'],
  },
  {
    field: 'name',
    allowedVendors: ['all'],
  },
  {
    field: 'enabled',
    defaultValue: true,
    allowedVendors: ['all'],
  },
  {
    field: 'lastErrorMsg',
    defaultValue: '',
    allowedVendors: ['all'],
  },
  {
    field: 'configuration',
    defaultValue: JSON.stringify(
      {
        publish: {
          enableAnalyticIndexing: true,
        },
      },
      null,
      '\t'
    ),
    allowedVendors: ['all'],
  },
  {
    field: 'integrationStatus',
    defaultValue: 'PENDING',
  },
  {
    field: 'updatedDate',
    type: 'date',
    server: true,
    defaultSort: true,
    sortDirection: 'desc',
  },
  {
    field: 'vendor',
    type: 'enum',
    options: [
      {
        label: 'AWS S3',
        value: 'CUSTOM',
      },
      {
        label: 'AZURE',
        value: 'AZUREINGRESS',
      },
      // ! Removed connections - https://voicebase.jira.com/browse/EA-5685
      // {
      //   label: 'Genesys',
      //   value: 'GENESYS',
      // },
      // {
      //   label: 'Twilio',
      //   value: 'TWILIO',
      // },
      {
        label: 'LivePerson Messaging',
        value: 'LIVEPERSON',
      },
      {
        label: 'LivePerson Chat',
        value: 'LIVEPERSONLIVECHAT',
      },
      {
        label: 'LivePerson Agent Status',
        value: 'LIVEPERSONAGENTSTATUS',
      },
    ],
    required: true,
    defaultValue: 'CUSTOM',
  },
  {
    field: 'type',
    type: 'enum',
    options: [
      {
        label: 'Media',
        value: 'media',
      },
      {
        label: 'Text',
        value: 'text',
      },
    ],
    required: true,
    defaultValue: 'media',
    allowedVendors: ['CUSTOM', 'AZUREINGRESS'],
  },
  {
    field: 'format',
    type: 'enum',
    options: [
      {
        label: 'Audio',
        value: 'SPEECH',
      },
      {
        label: 'Chat',
        value: 'CHAT',
      },
    ],
    defaultValue: 'SPEECH',
    required: true,
    allowedVendors: ['CUSTOM', 'AZUREINGRESS'],
  },
  {
    field: 'integrationType',
    type: 'enum',
    options: [
      {
        label: 'AWS S3',
        value: 'S3INGRESS',
      },
    ],
    defaultValue: 'S3INGRESS',
    required: true,
    allowedVendors: ['all'],
  },
  {
    field: 'role',
    required: true,
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 's3Url',
    required: true,
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 'sqsUrl',
    required: true,
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 'sqsResponseUrl',
    required: true,
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 'dlqUrl',
    defaultValue: '',
    required: true,
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 'folder',
    allowedVendors: ['CUSTOM'],
  },
  {
    field: 'clientId',
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'clientSecret',
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'blobStorageUrl',
    required: true,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'queueUrl',
    required: true,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'queueSasToken',
    required: true,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'blobStorageSasToken',
    required: true,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'successResponseQueueUrl',
    required: false,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'errorResponseQueueUrl',
    required: false,
    allowedVendors: ['AZUREINGRESS'],
  },
  {
    field: 'integrationAccessToken',
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'integrationTokenSecret',
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'watermarkTimestamp',
    type: 'date',
    required: true,
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'dataLoadMode',
    type: 'enum',
    options: [
      {
        label: 'Add',
        value: 'ADD',
      },
      {
        label: 'Skip',
        value: 'SKIP',
      },
      {
        label: 'Update',
        value: 'UPDATE',
      },
    ],
    required: true,
    defaultValue: 'ADD',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT'],
  },
  {
    field: 'metadataFilterRules',
    type: 'array',
    defaultValue: [],
  },
  {
    field: 'apiKeyId',
    defaultValue: null,
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'integrationUrl',
    defaultValue: '',
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  // {
  //   field: 'integrationUrlRegion',
  //   type: 'enum',
  //   optionsMessaging: [
  //     {
  //       label: 'Americas',
  //       value: 'https://va.msghist.liveperson.net',
  //     },
  //     {
  //       label: 'EU',
  //       value: 'https://lo.msghist.liveperson.net',
  //     },
  //     {
  //       label: 'APAC',
  //       value: 'https://sy.msghist.liveperson.net',
  //     },
  //     {
  //       label: 'Custom',
  //       value: 'CUSTOM',
  //     },
  //   ],
  //   optionsChat: [
  //     {
  //       label: 'Americas',
  //       value: 'https://va.enghist.liveperson.net',
  //     },
  //     {
  //       label: 'EU',
  //       value: 'https://lo.enghist.liveperson.net',
  //     },
  //     {
  //       label: 'APAC',
  //       value: 'https://sy.enghist.liveperson.net',
  //     },
  //     {
  //       label: 'Custom',
  //       value: 'CUSTOM',
  //     },
  //   ],
  //   optionsAgentStatus: [
  //     {
  //       label: 'Americas',
  //       value: 'https://va.agent-activity.liveperson.net',
  //     },
  //     {
  //       label: 'EU',
  //       value: 'https://lo.agent-activity.liveperson.net',
  //     },
  //     {
  //       label: 'APAC',
  //       value: 'https://sy.agent-activity.liveperson.net',
  //     },
  //     {
  //       label: 'Custom',
  //       value: 'CUSTOM',
  //     },
  //   ],
  //   required: true,
  //   defaultValue: 'Custom',
  //   allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  // },
  {
    field: 'checkDelay',
    defaultValue: false,
    allowedVendors: ['LIVEPERSON'],
  },
  {
    field: 'isCustomIntegrationUrl',
    defaultValue: false,
    allowedVendors: ['LIVEPERSON', 'LIVEPERSONLIVECHAT', 'LIVEPERSONAGENTSTATUS'],
  },
  {
    field: 'delayValue',
    defaultValue: null,
    type: 'enum',
    options: [
      {
        label: 'No Delay',
        name: 0,
      },
      {
        label: '30 minutes',
        name: 30,
      },
      {
        label: '60 minutes',
        name: 60,
      },
      {
        label: '120 minutes',
        name: 120,
      },
    ],
    allowedVendors: ['LIVEPERSON'],
  },
  {
    field: 'allowMinimumTtl',
    defaultValue: false,
    allowedVendors: ['LIVEPERSON','LIVEPERSONLIVECHAT'],
  },
  {
    field: 'minimumTtlDays',
    defaultValue: null,
    type: 'enum',
    options: [
      {
        label: '45 days',
        name: 45,
      },
      {
        label: '90 days',
        name: 90,
      },
      {
        label: '180 days',
        name: 180,
      },
      {
        label: '360 days',
        name: 360,
      },
    ],
    allowedVendors: ['LIVEPERSON','LIVEPERSONLIVECHAT'],
  }
  
  // {
  //   field: 'twilioUrl',
  //   type: 'string',
  //   defaultValue: 'http://www.urlhere.com',
  //   required: true,
  // },
];
const connectionModelByField = keyBy(connectionModel, 'field');

const connectionSchema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .test(...validations.beginOrEndWithSpace)
    .max(150, 'Limit 150 characters'),
  vendor: Yup.string()
    .max(255, 'Limit 255 characters')
    .test(...validations.stringWithSpaces)
    .test(...validations.trimSpaces),
  role: Yup.string()
    .nullable()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'CUSTOM',
      then: Yup.string()
        .required('Required')
        .max(255, 'Limit 255 characters')
        .test(...validations.trimSpaces)
        .test(
          'valid',
          'Format should be arn:aws:iam::<account_id>:role/<role_name>',
          (s3Role) => s3Role && isValidArnRoleString(s3Role)
        ),
      otherwise: Yup.string().nullable(true),
    }),
  s3Url: Yup.string()
    .nullable()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'CUSTOM',
      then: Yup.string()
        .required('Required')
        .max(255, 'Limit 255 characters')
        .test(...validations.trimSpaces)
        .test(...validations.validUrl),
      otherwise: Yup.string().nullable(true),
    }),
  sqsResponseUrl: Yup.string().when('vendor', {
    is: (vendor) => vendor && vendor === 'CUSTOM',
    then: Yup.string()
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces)
      .test(...validations.validUrl),
    otherwise: Yup.string().nullable(true),
  }),
  sqsUrl: Yup.string()
    .nullable()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'CUSTOM',
      then: Yup.string()
        .required('Required')
        .max(255, 'Limit 255 characters')
        .test(...validations.trimSpaces)
        .test(...validations.validUrl),
      otherwise: Yup.string().nullable(true),
    }),
  dlqUrl: Yup.string().when('vendor', {
    is: (vendor) => vendor && vendor === 'CUSTOM',
    then: Yup.string()
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces)
      .test(...validations.validUrl),
    otherwise: Yup.string().nullable(true),
  }),
  folder: Yup.string()
    .nullable()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'CUSTOM',
      then: Yup.string()
        .max(255, 'Limit 255 characters')
        .test(...validations.trimSpaces),
      otherwise: Yup.string().nullable(true),
    }),
  configuration: Yup.string().test(...validations.isValidJson),
  clientId: Yup.string().when('vendor', {
    is: (vendor) =>
      vendor &&
      (vendor === 'GENESYS' ||
        vendor === 'LIVEPERSON' ||
        vendor === 'LIVEPERSONLIVECHAT' ||
        vendor === 'LIVEPERSONAGENTSTATUS'),
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  clientSecret: Yup.string().when(['vendor', 'editMode'], {
    is: (vendor, editMode) =>
      vendor &&
      (vendor === 'GENESYS' ||
        vendor === 'LIVEPERSON' ||
        vendor === 'LIVEPERSONLIVECHAT' ||
        vendor === 'LIVEPERSONAGENTSTATUS') &&
      !editMode,
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  integrationAccessToken: Yup.string().when(['vendor', 'editMode'], {
    is: (vendor, editMode) =>
      vendor &&
      (vendor === 'LIVEPERSON' || vendor === 'LIVEPERSONLIVECHAT' || vendor === 'LIVEPERSONAGENTSTATUS') &&
      !editMode,
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  integrationTokenSecret: Yup.string().when(['vendor', 'editMode'], {
    is: (vendor, editMode) =>
      vendor &&
      (vendor === 'LIVEPERSON' || vendor === 'LIVEPERSONLIVECHAT' || vendor === 'LIVEPERSONAGENTSTATUS') &&
      !editMode,
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  watermarkTimestamp: Yup.date().when('vendor', {
    is: (vendor) =>
      vendor &&
      (vendor === 'GENESYS' ||
        vendor === 'LIVEPERSON' ||
        vendor === 'LIVEPERSONLIVECHAT' ||
        vendor === 'LIVEPERSONAGENTSTATUS'),
    then: Yup.date().required('Required').typeError('Must be a valid date'),
    otherwise: Yup.date().nullable(true),
  }),
  metadataFilterRules: Yup.array().when('vendor', {
    is: (vendor) => vendor && vendor === 'GENESYS',
    then: Yup.array().nullable(),
    otherwise: Yup.array().nullable(true),
  }),
  apiKeyId: Yup.string().when('vendor', {
    is: (vendor) =>
      vendor && (vendor === 'LIVEPERSON' || vendor === 'LIVEPERSONLIVECHAT' || vendor === 'LIVEPERSONAGENTSTATUS'),
    then: Yup.string()
      .nullable(true)
      .required('Required')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  integrationUrl: Yup.string().when('vendor', {
    is: (vendor) =>
      vendor && (vendor === 'LIVEPERSON' || vendor === 'LIVEPERSONLIVECHAT' || vendor === 'LIVEPERSONAGENTSTATUS'),
    then: Yup.string()
      .required('Required')
      .test(...validations.trimSpaces)
      .test(...validations.validUrl),
  }),
  delayValue: Yup.string()
    .typeError('Enter valid integer')
    .nullable(true)
    .when('checkDelay', {
      is: true,
      then: Yup.string()
        .typeError('Required')
        .required('Required')
        .test(...validations.numbersOnly),
    }),
    minimumTtlDays: Yup.string()
    .typeError('Enter valid integer')
    .nullable(true)
    .when('allowMinimumTtl', {
      is: true,
      then: Yup.string()
        .typeError('Required')
        .required('Required')
        .test(...validations.numbersOnly),
    }),
  blobStorageUrl: Yup.string()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'AZUREINGRESS',
      then: Yup.string()
        .required('Required')
        .test(...validations.trimSpaces)
        .test(
          'valid',
          'Format should be https://<StorageAccountName>.blob.core.windows.net/<ContainerName>',
          (blobStorageUrl) => blobStorageUrl && isValidAzureBlobServiceWithContainerURL(blobStorageUrl)
        ),
    })
    .nullable(true),
  queueUrl: Yup.string()
    .when('vendor', {
      is: (vendor) => vendor && vendor === 'AZUREINGRESS',
      then: Yup.string()
        .required('Required')
        .test(...validations.trimSpaces)
        .test(
          'valid',
          'Format should be https://<StorageAccountName>.queue.core.windows.net/<QueueName>',
          (queueUrl) => queueUrl && isValidAzureQueueURL(queueUrl)
        ),
    })
    .nullable(true),
  queueSasToken: Yup.string().when(['vendor', 'editMode'], {
    is: (vendor, editMode) => vendor && vendor === 'AZUREINGRESS' && !editMode,
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  blobStorageSasToken: Yup.string().when(['vendor', 'editMode'], {
    is: (vendor, editMode) => vendor && vendor === 'AZUREINGRESS' && !editMode,
    then: Yup.string()
      .required('Required')
      .max(255, 'Limit 255 characters')
      .test(...validations.trimSpaces),
    otherwise: Yup.string().nullable(true),
  }),
  successResponseQueueUrl: Yup.string().when('vendor', {
    is: (vendor) => vendor && vendor === 'AZUREINGRESS',
    then: Yup.string()
      .nullable(true)
      .test(...validations.trimSpaces)
      .test(
        'valid',
        'Format should be https://<StorageAccountName>.queue.core.windows.net/<QueueName>',
        (successResponseQueueUrl) =>
          typeof successResponseQueueUrl === 'undefined' ||
          successResponseQueueUrl === null ||
          (successResponseQueueUrl && isValidAzureQueueURL(successResponseQueueUrl))
      ),
    otherwise: Yup.string().nullable(true),
  }),
  errorResponseQueueUrl: Yup.string().when('vendor', {
    is: (vendor) => vendor && vendor === 'AZUREINGRESS',
    then: Yup.string()
      .nullable(true)
      .test(...validations.trimSpaces)
      .test(
        'valid',
        'Format should be https://<StorageAccountName>.queue.core.windows.net/<QueueName>',
        (errorResponseQueueUrl) =>
          typeof errorResponseQueueUrl === 'undefined' ||
          errorResponseQueueUrl === null ||
          (errorResponseQueueUrl && isValidAzureQueueURL(errorResponseQueueUrl))
      ),
    otherwise: Yup.string().nullable(true),
  }),
  // twilioUrl: Yup.string().when('vendor', {
  //   is: (vendor) => vendor && vendor === 'TWILIO',
  //   then: Yup.string().required('Required'),
  // }),
});

export { connectionModel, connectionModelByField, connectionVendors, connectionSchema };
