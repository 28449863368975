/** @prettier */
export const FETCH_USAGE_MONTHS = 'FETCH_USAGE_MONTHS';
export const FETCH_USAGE_MONTHS_ISLOADING = 'FETCH_USAGE_MONTHS_ISLOADING';
export const FETCH_USAGE_MONTHS_SUCCESS = 'FETCH_USAGE_MONTHS_SUCCESS';
export const FETCH_USAGE_MONTHS_FAILURE = 'FETCH_USAGE_MONTHS_FAILURE';
export const AAPLY_FILTERS_TO_USAGE = 'AAPLY_FILTERS_TO_USAGE';
export const FETCH_MONTH_USAGE = 'FETCH_MONTH_USAGE';
export const FETCH_MONTH_USAGE_ISLOADING = 'FETCH_MONTH_USAGE_ISLOADING';
export const FETCH_MONTH_USAGE_SUCCESS = 'FETCH_MONTH_USAGE_SUCCESS';
export const FETCH_MONTH_USAGE_FAILURE = 'FETCH_MONTH_USAGE_FAILURE';
export const FETCH_PAST_MONTHS_USAGE = 'FETCH_PAST_MONTHS_USAGE';
export const FETCH_PAST_MONTHS_USAGE_SUCCESS = 'FETCH_PAST_MONTHS_USAGE_SUCCESS';
export const FETCH_PAST_MONTHS_USAGE_FAILURE = 'FETCH_PAST_MONTHS_USAGE_FAILURE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const UPDATE_EMAIL_TOGGLE = 'UPDATE_EMAIL_TOGGLE';
export const USAGE_TRANSCRIPT_GRAPH = 'USAGE_TRANSCRIPT_GRAPH';
export const USAGE_TRANSCRIPT_GRAPH_SUCCESS = 'USAGE_TRANSCRIPT_GRAPH_SUCCESS';
export const USAGE_BILLING_DATA = 'USAGE_BILLING_DATA';
export const USAGE_BILLING_DATA_SUCCESS = 'USAGE_BILLING_DATA_SUCCESS';


