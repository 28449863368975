/** @prettier */
import { getBaseReducer } from 'utils';
import { communicationModel as model } from 'models/communication';
import actions from './actions';

const NUMBER_OF_ROWS = 50;
const initState = {
  ...getBaseReducer(model),
  entityType: 'communications',
  isBulkExportingTranscripts: false,
  lastSelectedPlayerData: [],
  defaultCommunicationLimit: NUMBER_OF_ROWS,
  communicationPage: 1,
  communicationFilters: {
    limit: `${NUMBER_OF_ROWS}&`,
  },
  downloadingStart: false,
  fetchedTranscripts:[]
};

export default function communicationReducer(state = initState, action) {
  switch (action.type) {
    case actions.BULK_EXPORT_TRANSCRIPTS:
      return {
        ...state,
        isBulkExportingTranscripts: true,
      };
    case actions.BULK_EXPORT_TRANSCRIPTS_SUCCESS:
      return {
        ...state,
        isBulkExportingTranscripts: false,
      };
    case actions.BULK_EXPORT_TRANSCRIPTS_FAILURE:
      return {
        ...state,
        isBulkExportingTranscripts: false,
      };
    case actions.SET_COMMUNICATIONS_FILTERS:
      return {
        ...state,
        communicationFilters: action.data,
      };
    case actions.SET_COMMUNICATIONS_PAGE:
      return {
        ...state,
        communicationPage: action.data,
      };
    case actions.SET_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case actions.CLEAR_COMMUNICATIONS:
      return {
        ...state,
        data: [],
        selectedItem: {},
        init: false,
      };
    case actions.SORT_COMMUNICATIONS:
      return {
        ...state,
        sort: action.sort,
      };
    case actions.FETCH_COMMUNICATIONS_SUCCESS: {
      const { data, links, pagination } = action.data;
      return { ...state, data, links, pagination, init: true };
    }

    case actions.GET_COMMUNICATION_SUCCESS: {
      return {
        ...state,
        lastSelectedPlayerData: action.data,
      };
    }
    case actions.SELECT_COMMUNICATION:
      return {
        ...state,
        selectedItem: action.selectedItem,
      };
    case actions.ADD_COMMUNICATION:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ADD_COMMUNICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.ADD_COMMUNICATION_SUCCESS:
      return {
        ...state,
        data: [action.selectedItem, ...state.data],
        selectedItem: action.selectedItem,
        view: false,
        isLoading: false,
      };
    case actions.EDIT_COMMUNICATION_SUCCESS:
      return {
        ...state,
        selectedItem: action.selectedItem,
        view: false,
      };
    case actions.DELETE__COMMUNICATION:
      return {
        ...state,
        data: action.data.filter((x) => x),
        selectedId: action.selectedId,
      };
    case actions.CHANGE_COMMUNICATION_VIEW:
      return {
        ...state,
        selectedItem: action.selectedItem || {},
        view: !state.view,
      };
      case actions.MEDIA_FILE_DOWNLOAD_START:
      return {
        ...state,
        downloadingStart: true
      };
      case actions.MEDIA_FILE_DOWNLOAD_STOP:
      return {
        ...state,
        downloadingStart: false
      };
      case actions.FETCH_FORMATTED_TRANSCRIPT: {
        const updatedTranscripts = {
          ...state.fetchedTranscripts,
          [action.mediaId]: action.data,
        };
        return {
          ...state,
          fetchedTranscripts: updatedTranscripts,
        };
      }
    default:
      return state;
  }
}
