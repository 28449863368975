/** @prettier */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-expressions */
import { all, fork, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { showToast } from 'utils/toast';
import api from 'services';

import {
  fetchUsageMonthsFailure,
  fetchUsageMonthsSuccess,
  fetchUsageMonthsIsLoading,
  fetchMonthUsageSuccess,
  fetchMonthUsageFailure,
  fetchMonthUsageIsLoading,
  updateActiveAccount,
  updateEmailToggle,
} from './actions';
import {
  FETCH_MONTH_USAGE,
  FETCH_USAGE_MONTHS,
  FETCH_PAST_MONTHS_USAGE,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_FAILURE,
  USAGE_TRANSCRIPT_GRAPH,
  USAGE_BILLING_DATA,
  USAGE_BILLING_DATA_SUCCESS,
  USAGE_TRANSCRIPT_GRAPH_SUCCESS
} from './constants';

export const getlatestMonth = (data) => {
  if (!data) return {};
  let latestMonth = data[0];

  data.forEach((d) => {
    latestMonth = latestMonth.monthCode > d.monthCode ? latestMonth : d;
  });

  return latestMonth;
};

export function* fetchUsageMonthsSaga() {
  yield takeLatest(FETCH_USAGE_MONTHS, function* months() {
    yield put(fetchUsageMonthsIsLoading(true));
    const res = yield call(api.get, {
      url: 'usage/months',
      disableNotification: true,
    });
    if (res?.data) {
      const { data } = res;
      yield put(fetchUsageMonthsSuccess(data));
    } else {
      yield put(fetchUsageMonthsFailure());
    }
    yield put(fetchUsageMonthsIsLoading(false));
  });
}

export function* fetchMonthUsageSaga() {
  yield takeEvery(FETCH_MONTH_USAGE, function* usage({ monthCode }) {
    yield put(fetchMonthUsageIsLoading({ isLoading: true, monthCode }));
    const res = yield call(api.get, {
      url: `usage/months/${monthCode}`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchMonthUsageSuccess(res.data));
    } else {
      const { history } = yield select((state) => state?.App || {});
      const { months } = yield select((state) => state?.usage || {});
      const { monthCode } = getlatestMonth(months);
      history?.push(`usage?date_string=${monthCode}`);
      yield put(fetchMonthUsageFailure());
    }
    yield put(fetchMonthUsageIsLoading({ isLoading: false, monthCode }));
  });
}

export function* fetchPastMonthsUsageSaga() {
  yield takeLatest(FETCH_PAST_MONTHS_USAGE, function* usage({ value }) {
    const res = yield call(api.get, {
      url: `usage/pastmonths/${value}`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put(fetchMonthUsageSuccess(res.data));
    } else {
      yield put(fetchMonthUsageFailure());
    }
  });
}

export function* updateSubscriptionSaga() {
  yield takeEvery(UPDATE_SUBSCRIPTION, function* ({ data, id }) {
    yield put(updateEmailToggle(data.account.subscribeToUsageEmail));
    const account = yield call(api.editItem, {
      entityType: 'accounts',
      data,
      id,
      activeAccountIdOverride: id,
      disableNotification: true,
      needResponse: true,
    });
    
    if (account?.status === 200) {
      showToast('success', {
        task: `Successfully ${
          data.account.subscribeToUsageEmail ? 'subscribed to' : 'unsubscribed from'
        } account usage emails`,
      });
      yield put(updateActiveAccount(account.data.account));
    } else {
      yield put(updateEmailToggle(!data.account.subscribeToUsageEmail));
      yield put({ type: UPDATE_SUBSCRIPTION_FAILURE });
    }
  });
}

export function* fetchUsageTranscriptGraphSaga() {
  yield takeLatest(USAGE_TRANSCRIPT_GRAPH, function* usage({ monthCode }) {
    const res = yield call(api.get, {
      url: `usage/months/${monthCode}/graph`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put({ type: USAGE_TRANSCRIPT_GRAPH_SUCCESS, data: res?.data});
    } else {
      yield put({ type: USAGE_TRANSCRIPT_GRAPH_SUCCESS, data: [] });
    }
  });
}

export function* fetchUsageBillingDataSaga() {
  yield takeLatest(USAGE_BILLING_DATA, function* usage({ monthCode }) {
    const res = yield call(api.get, {
      url: `usage/months/${monthCode}/bill`,
      disableNotification: true,
    });
    if (res?.data) {
      yield put({ type: USAGE_BILLING_DATA_SUCCESS, data: res?.data });
    } else {
      yield put({ type: USAGE_BILLING_DATA_SUCCESS, data: [] });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchUsageMonthsSaga),
    fork(fetchMonthUsageSaga),
    fork(fetchPastMonthsUsageSaga),
    fork(updateSubscriptionSaga),
    fork(fetchUsageTranscriptGraphSaga),
    fork(fetchUsageBillingDataSaga)
  ]);
}
