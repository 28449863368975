/** @prettier */
/* eslint-disable import/no-cycle */
import { all, takeEvery, fork, call, put } from 'redux-saga/effects';
import api from 'services';
import platformApi from 'services/platform';
import { bulkExportTranscripts, exportTranscript, saveBlob } from 'utils';
import { cloneDeep } from 'lodash';
import { showToast } from 'utils/toast';
import actions from './actions';
import { prepCommunication, transformCommunications } from '../../transform/communication';

const entityType = 'communications';

export function* fetchCommunicationsSaga() {
  yield takeEvery(actions.FETCH_COMMUNICATIONS, function* ({ filters }) {
    const data = yield call(api.fetchAll, {
      entityType,
      transform: transformCommunications,
      filters,
    });
    if (data) {
      yield put({ type: actions.FETCH_COMMUNICATIONS_SUCCESS, data });
    } else {
      yield put({ type: actions.FETCH_COMMUNICATIONS_FAILURE });
    }
  });
}

// This saga should only be used for getting individual medias, primarily used
// when PCI redaction flag is updated.
export function* getCommunicationSaga() {
  yield takeEvery(actions.GET_COMMUNICATION, function* ({ data }) {
    if (!data) {
      yield put({ type: actions.GET_COMMUNICATION_SUCCESS, data: null });
    } else {
      const item = yield call(api.fetchItem, {
        entityType,
        id: data,
      });

      if (item) {
        // item will typically be found if PCI redaction is NOT auto-delete
        yield put({ type: actions.GET_COMMUNICATION_SUCCESS, data: item });
      } else {
        // else, tentative solution to pass a status that is NOT 'finished' in order
        // to give user a sense of feedback
        yield put({ type: actions.GET_COMMUNICATION_SUCCESS, data: { mediaId: data, status: 'accepted' } });
      }
    }
  });
}

export function* setCommunicationsSaga() {
  yield takeEvery(actions.SET_COMMUNICATIONS, function* ({ data }) {
    yield put({ type: actions.SET_COMMUNICATIONS_SUCCESS, data });
  });
}

export function* addCommunicationSaga() {
  yield takeEvery(actions.ADD_COMMUNICATION, function* ({ data, onFinish }) {
    const communication = yield call(platformApi.createItemWithFormData, { entityType, data: prepCommunication(data) });
    if (communication) {
      yield put({ type: actions.ADD_COMMUNICATION_SUCCESS, selectedItem: communication });
      yield put({ type: actions.FETCH_COMMUNICATIONS });
    } else {
      yield put({ type: actions.ADD_COMMUNICATION_FAILURE });
    }
    onFinish();
  });
}

export function* editCommunicationSaga() {
  yield takeEvery(actions.EDIT_COMMUNICATION, function* ({ data, id, onFinish }) {
    const communication = yield call(api.editItem, { entityType, data: prepCommunication(data), id });
    yield put({ type: actions.EDIT_COMMUNICATION_SUCCESS, selectedItem: communication });
    yield put({ type: actions.FETCH_COMMUNICATIONS });
    onFinish();
  });
}

// export function* deleteCommunicationSaga() {
//   yield takeEvery(actions.DELETE__COMMUNICATION, function* () {
//   });
// }

export function* syncMediaToWarehouseSaga() {
  yield takeEvery(actions.SYNC_WAREHOUSE, function* () {
    yield call(platformApi.syncMediaToWarehouse);
  });
}

export function* bulkExportTranscriptsSaga() {
  yield takeEvery(actions.BULK_EXPORT_TRANSCRIPTS, function* ({ data }) {
    const clonedData = cloneDeep(data);
    const getTranscripts = () => {
      for (let i = 0; i < clonedData.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        const transcript = api.fetchItem({
          entityType,
          id: clonedData[i].mediaId,
          suffix: '/transcript/formattedText',
          disableNotification: true,
        });

        if (!transcript?.isAxiosError) {
          clonedData[i].transcript = transcript;
        }
      }
    };
    yield getTranscripts();

    const filteredDataWithTranscripts = clonedData.filter((datum) => {
      return datum.transcript;
    });
    if (filteredDataWithTranscripts.length) {
      yield bulkExportTranscripts(filteredDataWithTranscripts);
      yield put({ type: actions.BULK_EXPORT_TRANSCRIPTS_SUCCESS });

      showToast('success', {
        task: 'Exported transcripts successfully',
      });
    } else {
      yield put({ type: actions.BULK_EXPORT_TRANSCRIPTS_FAILURE });

      showToast('error', {
        task: 'No transcripts to export',
      });
    }
  });
}


export function* mediaDownloadSaga() {
  yield takeEvery(actions.MEDIA_FILE_DOWNLOAD, function* ({ data }) {
    try {
      yield put({ type: actions.MEDIA_FILE_DOWNLOAD_START });
      const res = yield call(platformApi.postReqFile, { url: 'media/download', data, responseTypeBlob:true });
      yield put({ type: actions.MEDIA_FILE_DOWNLOAD_STOP });
      // Save the file
      saveBlob(res, 'media_file.csv');
      showToast('success', {
        task: 'File successfully downloaded',
      });
    } catch (error) {
      console.error('Error downloading the file', error);
      yield put({ type: actions.MEDIA_FILE_DOWNLOAD_STOP });
      // Handle error if needed
    }
  });
}

export function* transcriptDownloadSaga() {
  yield takeEvery(actions.DOWNLOAD_TEXT_TRANSCRIPT, function* ({ mediaId, data,cb }) {
    try {
      if(!data.download){
        yield put({ type: actions.FETCH_FORMATTED_TRANSCRIPT, data: {loading:true,data:""}, mediaId });
      }
      const res = yield call(platformApi.platformGetReq, { urlPath: `media/${mediaId}/transcript/formattedText`, disableNotification: true });
      if(data.download){
        exportTranscript(res,mediaId)
      }else{
        yield put({ type: actions.FETCH_FORMATTED_TRANSCRIPT, data: {loading:false,data:res}, mediaId });
      }
      cb(res)
    } catch (error) {
      cb(error)
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(fetchCommunicationsSaga),
    fork(getCommunicationSaga),
    fork(addCommunicationSaga),
    fork(editCommunicationSaga),
    // fork(deleteCommunicationSaga),
    fork(syncMediaToWarehouseSaga),
    fork(setCommunicationsSaga),
    fork(bulkExportTranscriptsSaga),
    fork(mediaDownloadSaga),
    fork(transcriptDownloadSaga)
  ]);
}
