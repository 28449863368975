/** @prettier */
import {
  FETCH_USAGE_MONTHS_ISLOADING,
  FETCH_USAGE_MONTHS_FAILURE,
  FETCH_USAGE_MONTHS_SUCCESS,
  AAPLY_FILTERS_TO_USAGE,
  FETCH_MONTH_USAGE_ISLOADING,
  FETCH_MONTH_USAGE_SUCCESS,
  FETCH_MONTH_USAGE_FAILURE,
  FETCH_PAST_MONTHS_USAGE,
  SET_SELECTED_DATE,
  UPDATE_SUBSCRIPTION_FAILURE,
  UPDATE_EMAIL_TOGGLE,
  USAGE_BILLING_DATA_SUCCESS,
  USAGE_TRANSCRIPT_GRAPH_SUCCESS,
} from './constants';

const initState = {
  loading: false,
  data: [],
  filters: {
    language: '',
    priority: '',
    type: 'billedQuantity',
  },
  months: [],
  validDates: {},
  past: false,
  pastValue: null,
  emailUsageToggleValue: false,
  graphUsageData:[],
  usageBillingData:[],
  billingUsage: []
};

export default function usageReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_USAGE_MONTHS_ISLOADING:
      return {
        ...state,
        loading: action.isLoading,
      };
    case FETCH_USAGE_MONTHS_SUCCESS:
      return {
        ...state,
        loading: !!(action?.data || []).length, // deepscan-disable-line INSUFFICIENT_NULL_CHECK
        months: action.data,
      };
    case FETCH_USAGE_MONTHS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case AAPLY_FILTERS_TO_USAGE:
      return {
        ...state,
        filters: { ...state.filters, ...action.filter },
      };
    case FETCH_MONTH_USAGE_ISLOADING:
      return {
        ...state,
        loading: action.data.isLoading,
      };
    case SET_SELECTED_DATE:
      return {
        ...state,
        selectedYear: action.monthCode.substr(0, 4),
        selectedMonth: action.monthCode.substr(4),
      };
    case FETCH_MONTH_USAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: Object.entries(action.data.billingDetailsMap).sort((a, b) => new Date(a[0]) - new Date(b[0])),
      };
    case FETCH_MONTH_USAGE_FAILURE:
      return {
        ...state,
      };
    case FETCH_PAST_MONTHS_USAGE:
      return {
        ...state,
        past: true,
        pastValue: action.value,
        selectedYear: null,
        selectedMonth: null,
      };
    case UPDATE_SUBSCRIPTION_FAILURE:
      return { ...state };
    case UPDATE_EMAIL_TOGGLE:
      return {
        ...state,
        emailUsageToggleValue: action.value,
      };
    case USAGE_TRANSCRIPT_GRAPH_SUCCESS:
      return {
        ...state,
        graphUsageData: Object.entries(action.data.billingDetailsMap).sort((a, b) => new Date(a[0]) - new Date(b[0])),
      };
      case USAGE_BILLING_DATA_SUCCESS:
        return {
          ...state,
          billingUsage: Object.entries(action.data.billingDetailsMap).sort((a, b) => new Date(a[0]) - new Date(b[0])),
        };
    default:
      return state;
  }
}
