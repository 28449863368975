/** @prettier */
import _ from 'lodash';
import { convertToISO8601UTC, getTimeFromNow, searchData } from 'utils';
import { handleCsvBadValue, wrapCsvValue } from 'utils/csv';

export const splitName = (dataName = '') => {
  const name = dataName;
  const label = name.replace(/.*_/, '');
  let collectionAndGroupsArr = name.split('_');
  collectionAndGroupsArr.pop();

  // check to see if the first group is a collection, then set 'collection'
  const firstGroup = _.get(collectionAndGroupsArr, '[0]', '');
  const hasCollection = firstGroup.length > 0;
  const collection = hasCollection ? firstGroup : 'Unknown';

  // Needed to create dropdown filter:
  collectionAndGroupsArr = collectionAndGroupsArr.filter((i) => i); // filter out missing collections
  const collectionAndGroups = collectionAndGroupsArr.length ? [collectionAndGroupsArr.join('_')] : [];

  if (hasCollection) collectionAndGroupsArr.shift();
  const groups = collectionAndGroupsArr.length ? collectionAndGroupsArr.join('_') : '';
  return {
    collection,
    collectionStr: collection === 'Unknown' ? '-' : collection,
    groups,
    label,
    collectionAndGroups,
  };
};

const transformToBulkDelete = (categories) =>
  categories.map(({ categoryName }) => ({ operation: 'DELETE', data: { categoryName } }));
const transformtoBulkToBulkPublish = (categories, publish) =>
  categories.map((i) => ({ operation: 'PATCH', data: { ...i, active: publish } }));

export const transformBulkCategory = (data, isDelete, isPublish = true) =>
  // https://voicebase.jira.com/wiki/spaces/PRED/pages/2161836123/Bulk+Category+Operations+API
  // We either bulk delete or bulk publish
  ({
    operations: isDelete ? transformToBulkDelete(data) : transformtoBulkToBulkPublish(data, isPublish),
  });

export const transformCategory = (data, dependencies, jobs) => {
  const tags = data.tags || [];
  const name = data.name || data.categoryName;
  const namesObj = splitName(name);
  const status = jobs ? _.get(jobs, name, 'Ready') : '-';

  return {
    ...data,
    ...namesObj,
    activeColor: data.active ? '#19a900' : '#c3c3c3',
    version: data.version || '',
    tags: tags.filter((x) => !!x),
    tagsStr: tags.length ? tags.join(', ') : '-',
    dependencies: _.get(dependencies, data.name, []),
    status: _.capitalize(status),
    selected: false,
  };
};

/*
// disabled pending backend functionality supporting this feature.
// gets all references to other categories within a query
const getDependencies = data => {
  const term = 'categories\.';
  const termLength = 11;
  const re = new RegExp('(\\b' + term + '\\w*)', 'gi');
  return data.reduce(((result, curr) => {
    const query = _.get(curr, 'query', '');
    const matches = query.match(re);
    const dependencies = matches ? matches.reduce(((r, c) => {
      const cat = c.substring(termLength);
      const prev = _.get(result, cat, []);
      return { ...r, [cat]: [...prev, curr.name] };
    }), {}): {};
    return {
      ...result,
      ...dependencies,
    };
  }), {});
};
*/

export const transformCategories = (data = [], jobs = {}) => {
  const dependencies = {}; // getDependencies(data);
  return data.map((x) => transformCategory(x, dependencies, jobs));
};

const fieldsToRemove = [
  'dateModified',
  'dateCreated',
  'activeColor',
  'collectionAndGroups',
  'label',
  'groups',
  'collectionStr',
  'tagsStr',
  'status',
  'dependencies',
  'collection',
  'created',
  'modified',
  'categoryname',
  'name',
  'selected',
];
export const prepCategory = (data) => {
  const { collection } = data;
  const categoryName =
    'categoryName' in data && data.categoryName !== ''
      ? data.categoryName
      : [collection, data.groups, data.label].filter((x) => x).join('_');
  const tags = data.tags && data.tags.length > 0 ? data.tags.map((x) => x.trim()).filter((x) => x) : [];
  const categoryDescription = JSON.stringify(data.categoryDescription || data.description || '').replace(
    /(\\n|")/g,
    ''
  );
  const notes = JSON.stringify(data.notes || '').replace(/(\\n|")/g, '');
  return _.omit(
    {
      ...data,
      categoryDescription,
      notes,
      tags,
      categoryName,
    },
    fieldsToRemove
  );
};

export const prepCategories = (data) => data.map((x) => prepCategory(x));

export const filterCategories = (data, filters) => {
  if (!filters) return data;
  let result = data;

  if ('groups' in filters && filters.groups.length && filters.groups[0]) {
    // Simplified group searching also returns child group contents
    const groupFilter = `${filters.groups[0].toLowerCase()}_`;
    result = data.filter((x) => {
      const name = x.categoryName.toLowerCase();
      return name.indexOf(groupFilter) === 0 || name.indexOf(groupFilter) === 1;
    });
    // The following is for more complex group searching:
    // Filters data that DO NOT have intersecting groups
    // result = data.filter(x => x.groups ? hasIntersection(filters.groups, x.groups, false) : x);
  }

  return searchData(result, filters, ['name', 'categoryName', 'tagsStr']);
};

export const transformImportCategories = ({ data, mappings }) => {
  const prepped = data.map((d) => {
    const result = {};
    _.forOwn(d, (value, key) => (result[key] = value === null ? '' : _.toString(value)));
    return result;
  });
  const mapped = prepped.map((d) => Object.entries(mappings).reduce((a, [k, v]) => (v ? ((a[k] = d[v]), a) : a), {}));
  const mods = mapped.map((d) => {
    const tags = d.tags || '';
    const categoryDescription = d.categoryDescription || '';
    const notes = d.notes || '';
    const version = d.version || '';
    return {
      ...d,
      collection: d.collection ? d.collection : '',
      tags: tags
        .split(',')
        .filter((x) => x && x !== '-')
        .map((x) => x.trim())
        .filter((x) => x),
      active: 'active' in d && (d.active === 'true' || d.active === true),
      notes: notes.trim().replace(/[“”]/gi, '"').replace(/[’‘]/gi, "'"),
      categoryDescription: categoryDescription.trim().replace(/[“”]/gi, '"').replace(/[’‘]/gi, "'"),
      version: version.trim(),
    };
  });
  return mods;
};

export const prepCategoryForCsv = (x) => {
  return {
    published: x.active ? 'true' : 'false',
    collection: x.collection === 'Unknown' ? '' : x.collection,
    groups: x.groups,
    label: x.label,
    query: x.query.replace(/"/g, '""'),
    description: wrapCsvValue(handleCsvBadValue(x.categoryDescription)),
    tags: x.tagsStr === '-' ? '' : wrapCsvValue(handleCsvBadValue(x.tagsStr)),
    created: convertToISO8601UTC(x.dateCreated),
    modified: convertToISO8601UTC(x.dateModified),
    version: wrapCsvValue(handleCsvBadValue(x.version)),
    notes: wrapCsvValue(handleCsvBadValue(x.notes)),
  };
};
