/** @prettier */
const uploadMediaActions = {
  SET_CSV_FILE_UPLOAD_STATE: 'SET_CSV_FILE_UPLOAD_STATE',
  SET_UPLOAD_MEDIA_URL: 'SET_UPLOAD_MEDIA_URL',
  SET_UPLOAD_NAME: 'SET_UPLOAD_NAME',
  SET_UPLOAD_DESCRIPTION: 'SET_UPLOAD_DESCRIPTION',
  SET_CSV_FILE_HEADERS: 'SET_CSV_FILE_HEADERS',
  SET_PARSED_CSV_FILE_ARRAY: 'SET_PARSED_CSV_FILE_ARRAY',
  SET_UPLOADER_FIELD: 'SET_UPLOADER_FIELD',
  SET_UPLOADED_FILE_INTERACTION_TYPE: 'SET_UPLOADED_FILE_INTERACTION_TYPE',
  SET_SPEECH_MODEL_NAME: 'SET_SPEECH_MODEL_NAME',
  SET_SPEECH_MODEL_LANGUAGE: 'SET_SPEECH_MODEL_LANGUAGE',
  SET_SPEECH_MODEL_FEATURES: 'SET_SPEECH_MODEL_FEATURES',
  SET_KNOWLEDGE_ENABLE_DISCOVERY: 'SET_KNOWLEDGE_ENABLE_DISCOVERY',
  SET_KNOWLEDGE_ENABLE_EXTERNAL_DATA_SOURCES: 'SET_KNOWLEDGE_ENABLE_EXTERNAL_DATA_SOURCES',
  SET_CONVERSATION_VERB_NOUN_PAIRS_ENABLED: 'SET_CONVERSATION_VERB_NOUN_PAIRS_ENABLED',
  SET_PUBLISH_ENABLE_ANALYTIC_INDEXING: 'SET_PUBLISH_ENABLE_ANALYTIC_INDEXING',
  SET_CATEGORIES_ALL_CATEGORIES: 'SET_CATEGORIES_ALL_CATEGORIES',
  SET_SPEAKER_SENTIMENTS: 'SET_SPEAKER_SENTIMENTS',
  SET_ENTITY_ENABLE_ENTITY_EXTRACTION: 'SET_ENTITY_ENABLE_ENTITY_EXTRACTION',
  SET_PREDICTION_DETECTORS: 'SET_PREDICTION_DETECTORS',
  SET_CONFIG_METRICS: 'SET_CONFIG_METRICS',
  RESET_UPLOAD_MEDIA_FORM: 'RESET_UPLOAD_MEDIA_FORM',
  RESET_UPLOAD_CONFIG: 'RESET_UPLOAD_CONFIG',
  RESET_CONFIG_INGEST: 'RESET_CONFIG_INGEST',
  RESET_CONFIG_INGEST_CHANNELS: 'RESET_CONFIG_INGEST_CHANNELS',
  SET_INGEST_CHANNELS: 'SET_INGEST_CHANNELS',
  SET_INGEST: 'SET_INGEST',
  SET_TRANSCRIPT_FORMATTING_ENABLE_NUMBER_FORMATTING: 'SET_TRANSCRIPT_FORMATTING_ENABLE_NUMBER_FORMATTING',
  SET_CONFIG_PRIORITY: 'SET_CONFIG_PRIORITY',
  SET_CUSTOM_VOCABULARY_LIST_ITEMS: 'SET_CUSTOM_VOCABULARY_LIST_ITEMS',
  SET_CONFIG_CUSTOM_VOCABULARY: 'SET_CONFIG_CUSTOM_VOCABULARY',
  SET_CONFIG_ADHOC_VOCABULARY_LIST: 'SET_CONFIG_ADHOC_VOCABULARY_LIST',
  SET_CONFIG_ADVANCED_PUNCTUATION: 'SET_CONFIG_ADVANCED_PUNCTUATION',
  SET_CONFIG_VOICEMAIL: 'SET_CONFIG_VOICEMAIL',
  SET_CONFIG_CONVERT_FILE_FORMAT: 'SET_CONFIG_CONVERT_FILE_FORMAT',
  SET_KEY_WORD_SPOTTING_LIST: 'SET_KEY_WORD_SPOTTING_LIST',
  SET_CONFIG_NUMBER_BLASTER: 'SET_CONFIG_NUMBER_BLASTER',
  SET_CONFIG_PCI_DETECTION: 'SET_CONFIG_PCI_DETECTION',
  SET_CONFIG_PCI_REDACTION: 'SET_CONFIG_PCI_REDACTION',
  SET_CONFIG_SSN_DETECTION: 'SET_CONFIG_SSN_DETECTION',
  SET_CONFIG_SSN_REDACTION: 'SET_CONFIG_SSN_REDACTION',
  SET_CONFIG_SWEAR_WORD_FILTER: 'SET_CONFIG_SWEAR_WORD_FILTER',
  SET_CONFIG_PREDICTION_CLASSIFIERS: 'SET_CONFIG_PREDICTION_CLASSIFIERS',
  SET_CONFIG_SELECT_CATEGORIES: 'SET_CONFIG_SELECT_CATEGORIES',
  SET_CONFIG_CONVERSATION_METRICS: 'SET_CONFIG_CONVERSATION_METRICS',
  SET_CONFIG_CALLBACK_URLS: 'SET_CONFIG_CALLBACK_URLS',
  SET_CONFIG_CALLBACK_METHODS: 'SET_CONFIG_CALLBACK_METHODS',
  SET_CONFIG_CALLBACK_SKIP_IF_NOT_REDACTED: 'SET_CONFIG_CALLBACK_SKIP_IF_NOT_REDACTED',
  SET_CONNECTION_DATA: 'SET_CONNECTION_DATA',
  SET_CONFIG_WITH_ADVANCED_CONFIG: 'SET_CONFIG_WITH_ADVANCED_CONFIG',
  SET_NEW_ADVANCED_CONFIG: 'SET_NEW_ADVANCED_CONFIG',
  SET_NEW_ADVANCED_CONFIG_FILE: 'SET_NEW_ADVANCED_CONFIG_FILE',
  SET_CONFIG_VOCABULARIES: 'SET_CONFIG_VOCABULARIES',
  SET_NEW_ADVANCED_METADATA: 'SET_NEW_ADVANCED_METADATA',
  SET_NEW_ADVANCED_METADATA_FILE: 'SET_NEW_ADVANCED_METADATA_FILE',
  SET_METADATA_WITH_ADVANCED_METADATA: 'SET_METADATA_WITH_ADVANCED_METADATA',
  SET_METADATA_WITH_STANDARD_METADATA: 'SET_METADATA_WITH_STANDARD_METADATA',
  UPDATE_METADATA_WITH_ENTERED_METADATA: 'UPDATE_METADATA_WITH_ENTERED_METADATA',
  SET_METADATA_STANDARD_UPLOAD_FILE: 'SET_METADATA_STANDARD_UPLOAD_FILE',
  SET_STANDARD_ENTERED_METADATA: 'SET_STANDARD_ENTERED_METADATA',
  SET_METADATA_FROM_UPLOADED_FILE: 'SET_METADATA_FROM_UPLOADED_FILE',
  SET_NEW_CONVERSATION_METRICS:"SET_NEW_CONVERSATION_METRICS",
  resetUploadMediaForm: () => ({
    type: uploadMediaActions.RESET_UPLOAD_MEDIA_FORM,
  }),
  resetUploadConfig: () => ({
    type: uploadMediaActions.RESET_UPLOAD_CONFIG,
  }),
  resetConfigIngest: () => ({
    type: uploadMediaActions.RESET_CONFIG_INGEST,
  }),
  resetConfigIngestChannels: () => ({
    type: uploadMediaActions.RESET_CONFIG_INGEST_CHANNELS,
  }),

  setHasCSVFileBeenUploaded: (isCsvFileUploaded) => ({
    type: uploadMediaActions.SET_CSV_FILE_UPLOAD_STATE,
    hasCSVFileBeenUploaded: isCsvFileUploaded,
  }),
  setUploadMediaUrl: (uploadMediaUrl) => ({
    type: uploadMediaActions.SET_UPLOAD_MEDIA_URL,
    uploadMediaUrl,
  }),
  setUploadName: (uploadName) => ({
    type: uploadMediaActions.SET_UPLOAD_NAME,
    uploadName,
  }),
  setUploadDescription: (uploadDescription) => ({
    type: uploadMediaActions.SET_UPLOAD_DESCRIPTION,
    uploadDescription,
  }),
  setCSVHeaders: (csvFileHeaders) => ({
    type: uploadMediaActions.SET_CSV_FILE_HEADERS,
    csvFileHeaders,
  }),
  setParsedCSVFileArray: (parsedCSVFileArray) => ({
    type: uploadMediaActions.SET_PARSED_CSV_FILE_ARRAY,
    parsedCSVFileArray,
  }),
  setUploaderField: (uploaderField) => ({
    type: uploadMediaActions.SET_UPLOADER_FIELD,
    uploaderField,
  }),
  setUploadedFileInteractionType: (fileInteractionType) => ({
    type: uploadMediaActions.SET_UPLOADED_FILE_INTERACTION_TYPE,
    fileInteractionType,
  }),
  setSpeechModelName: (speechModelName) => ({
    type: uploadMediaActions.SET_SPEECH_MODEL_NAME,
    speechModelName,
  }),
  setSpeechModelLanguage: (speechModelLanguage) => ({
    type: uploadMediaActions.SET_SPEECH_MODEL_LANGUAGE,
    speechModelLanguage,
  }),
  setSpeechModelFeatures: (event, speechModelFeatures) => ({
    type: uploadMediaActions.SET_SPEECH_MODEL_FEATURES,
    event,
    speechModelFeatures,
  }),
  setKnowledgeEnableDiscovery: (enableDiscovery) => ({
    type: uploadMediaActions.SET_KNOWLEDGE_ENABLE_DISCOVERY,
    enableDiscovery,
  }),
  setKnowledgeEnableExternalDataSources: (enableExternalDataSources) => ({
    type: uploadMediaActions.SET_KNOWLEDGE_ENABLE_EXTERNAL_DATA_SOURCES,
    enableExternalDataSources,
  }),
  setConversationVerbNounPairsEnabled: (verbNounPairsEnabled) => ({
    type: uploadMediaActions.SET_CONVERSATION_VERB_NOUN_PAIRS_ENABLED,
    verbNounPairsEnabled,
  }),
  setPublishEnableAnalyticIndexing: (enableAnalyticIndexing) => ({
    type: uploadMediaActions.SET_PUBLISH_ENABLE_ANALYTIC_INDEXING,
    enableAnalyticIndexing,
  }),
  setCategoriesAllCategories: (allCategories) => ({
    type: uploadMediaActions.SET_CATEGORIES_ALL_CATEGORIES,
    allCategories,
  }),
  setSpeakerSentiments: (speakerSentiments) => ({
    type: uploadMediaActions.SET_SPEAKER_SENTIMENTS,
    speakerSentiments,
  }),
  setEnableEntityExtraction: (enableEntityExtraction) => ({
    type: uploadMediaActions.SET_ENTITY_ENABLE_ENTITY_EXTRACTION,
    enableEntityExtraction,
  }),
  setPredictionDetectors: (predictionDetectors) => ({
    type: uploadMediaActions.SET_PREDICTION_DETECTORS,
    predictionDetectors,
  }),
  setConfigMetrics: (metricsData) => ({
    type: uploadMediaActions.SET_CONFIG_METRICS,
    metricsData,
  }),
  setIngestChannels: (ingestChannels) => ({
    type: uploadMediaActions.SET_INGEST_CHANNELS,
    ingestChannels,
  }),
  setIngest: (ingest) => ({
    type: uploadMediaActions.SET_INGEST,
    ingest,
  }),
  setTranscriptFormattingEnableNumberFormatting: (enableNumberFormatting) => ({
    type: uploadMediaActions.SET_TRANSCRIPT_FORMATTING_ENABLE_NUMBER_FORMATTING,
    enableNumberFormatting,
  }),
  setConfigPriority: (priority) => ({
    type: uploadMediaActions.SET_CONFIG_PRIORITY,
    priority,
  }),
  setCustomVocabularyListItems: (customVocabularyListItems, isVocabularyItemListLoaded) => ({
    type: uploadMediaActions.SET_CUSTOM_VOCABULARY_LIST_ITEMS,
    customVocabularyListItems,
    isVocabularyItemListLoaded,
  }),
  setConfigCustomVocabulary: (vocabularies, index = null) => ({
    type: uploadMediaActions.SET_CONFIG_CUSTOM_VOCABULARY,
    vocabularies,
    index,
  }),
  setAdhocVocabularyList: (data, path) => ({
    type: uploadMediaActions.SET_CONFIG_ADHOC_VOCABULARY_LIST,
    data,
    path,
  }),
  setConfigVocabularies: (vocabularies) => ({
    type: uploadMediaActions.SET_CONFIG_VOCABULARIES,
    vocabularies,
  }),
  setConfigAdvancedPunctuation: (event) => ({
    type: uploadMediaActions.SET_CONFIG_ADVANCED_PUNCTUATION,
    event,
  }),
  setConfigVoicemail: (event) => ({
    type: uploadMediaActions.SET_CONFIG_VOICEMAIL,
    event,
  }),
  setConfigConvertFileFormat: (fileFormat) => ({
    type: uploadMediaActions.SET_CONFIG_CONVERT_FILE_FORMAT,
    fileFormat,
  }),
  setKeywordSpottingList: (spottingList) => ({
    type: uploadMediaActions.SET_KEY_WORD_SPOTTING_LIST,
    spottingList,
  }),
  setConfigNumberBlaster: (event, numberBlaster) => ({
    type: uploadMediaActions.SET_CONFIG_NUMBER_BLASTER,
    event,
    numberBlaster,
  }),
  setConfigPciDetection: (pciDetection) => ({
    type: uploadMediaActions.SET_CONFIG_PCI_DETECTION,
    pciDetection,
  }),
  setConfigPciRedaction: (pciRedaction) => ({
    type: uploadMediaActions.SET_CONFIG_PCI_REDACTION,
    pciRedaction,
  }),
  setConfigSsnDetection: (ssnDetection) => ({
    type: uploadMediaActions.SET_CONFIG_SSN_DETECTION,
    ssnDetection,
  }),
  setConfigSsnRedaction: (ssnRedaction) => ({
    type: uploadMediaActions.SET_CONFIG_SSN_REDACTION,
    ssnRedaction,
  }),
  setConfigSwearWordFilter: (swearWordFilter) => ({
    type: uploadMediaActions.SET_CONFIG_SWEAR_WORD_FILTER,
    swearWordFilter,
  }),
  setConfigPredictionClassifiers: (predictionClassifiers) => (
   {
    type: uploadMediaActions.SET_CONFIG_PREDICTION_CLASSIFIERS,
    predictionClassifiers,
  }),
  setConfigSelectCategories: (selectCategories) => ({
    type: uploadMediaActions.SET_CONFIG_SELECT_CATEGORIES,
    selectCategories,
  }),
  setConfigConversationMetrics: (event, metrics) => ({
    type: uploadMediaActions.SET_CONFIG_CONVERSATION_METRICS,
    event,
    metrics,
  }),
  setConfigCallbackUrls: (callbackType, urlAnalytics) => ({
    type: uploadMediaActions.SET_CONFIG_CALLBACK_URLS,
    callbackType,
    urlAnalytics,
  }),
  setConfigCallbackMethods: (callbackType, method) => ({
    type: uploadMediaActions.SET_CONFIG_CALLBACK_METHODS,
    callbackType,
    method,
  }),
  setConfigCallbackSkipIfNotRedacted: (callbackType, event) => ({
    type: uploadMediaActions.SET_CONFIG_CALLBACK_SKIP_IF_NOT_REDACTED,
    callbackType,
    event,
  }),
  setConnectionData: (connectionData) => ({
    type: uploadMediaActions.SET_CONNECTION_DATA,
    connectionData,
  }),
  setConfigWithAdvancedConfig: (advancedConfig) => ({
    type: uploadMediaActions.SET_CONFIG_WITH_ADVANCED_CONFIG,
    advancedConfig,
  }),
  setNewAdvancedConfig: (advancedConfig) => ({
    type: uploadMediaActions.SET_NEW_ADVANCED_CONFIG,
    advancedConfig,
  }),
  setNewAdvancedConfigFile: (advancedConfigFile) => ({
    type: uploadMediaActions.SET_NEW_ADVANCED_CONFIG_FILE,
    advancedConfigFile,
  }),
  setNewAdvancedMetadata: (advancedMetadata) => ({
    type: uploadMediaActions.SET_NEW_ADVANCED_METADATA,
    advancedMetadata,
  }),
  setNewAdvancedMetadataFile: (advancedMetadataFile) => ({
    type: uploadMediaActions.SET_NEW_ADVANCED_METADATA_FILE,
    advancedMetadataFile,
  }),
  setMetadataWithAdvancedMetadata: () => ({
    type: uploadMediaActions.SET_METADATA_WITH_ADVANCED_METADATA,
  }),
  setMetadataWithStandardMetadata: (standardMetadata) => ({
    type: uploadMediaActions.SET_METADATA_WITH_STANDARD_METADATA,
    standardMetadata,
  }),
  updateMetadataWithEnteredMetadata: (updatedMetadata) => ({
    type: uploadMediaActions.UPDATE_METADATA_WITH_ENTERED_METADATA,
    updatedMetadata,
  }),
  setMetadataStandardUploadFile: (file) => ({
    type: uploadMediaActions.SET_METADATA_STANDARD_UPLOAD_FILE,
    file,
  }),
  setStandardEnteredMetadata: (enteredMetaDataDetail) => ({
    type: uploadMediaActions.SET_STANDARD_ENTERED_METADATA,
    enteredMetaDataDetail,
  }),
  setMetadataFromUploadedFile: (metadataFromUploadedFile) => ({
    type: uploadMediaActions.SET_METADATA_FROM_UPLOADED_FILE,
    metadataFromUploadedFile,
  }),
  setNewConversationMetrics: (metrics) => ({
    type: uploadMediaActions.SET_NEW_CONVERSATION_METRICS,
    metrics
  })
};
export default uploadMediaActions;
