const playerActions = {
  SHOW_PLAYER: 'SHOW_PLAYER',
  HIDE_PLAYER: 'HIDE_PLAYER',
  FETCH_MEDIA_JSON: 'FETCH_MEDIA_JSON',
  FETCH_MEDIA_JSON_SUCCESS: 'FETCH_MEDIA_JSON_SUCCESS',
  FETCH_MEDIA_JSON_ERROR: 'FETCH_MEDIA_JSON_ERROR',
  FETCH_PII_FLAG: 'FETCH_PII_FLAG',
  FETCH_PII_FLAG_SUCCESS: 'FETCH_PII_FLAG_SUCCESS',
  FETCH_PII_FLAG_ERROR: 'FETCH_PII_FLAG_ERROR',
  FETCH_PII_FLAG_ACTION: 'FETCH_PII_FLAG_ACTION',
  FETCH_PII_FLAG_ACTION_SUCCESS: 'FETCH_PII_FLAG_ACTION_SUCCESS',
  FETCH_PII_FLAG_ACTION_ERROR: 'FETCH_PII_FLAG_ACTION_ERROR',
  CHANGE_MEDIA_ACTION_FLAG: 'CHANGE_MEDIA_ACTION_FLAG',
  UPDATE_PII_FLAG: 'UPDATE_PII_FLAG',
  UPDATE_PII_FLAG_ERROR: 'UPDATE_PII_ERROR',
  UPDATE_PII_FLAG_SUCCESS: 'UPDATE_PII_SUCCESS',
  MINIMIZE_PLAYER: 'MINIMIZE_PLAYER',
  EXPORT_TRANSCRIPT_SUCCESS: 'EXPORT_TRANSCRIPT_SUCCESS',

  exportTranscript: (data) => ({
    type: playerActions.EXPORT_TRANSCRIPT_SUCCESS,
    data
  }),
  showPlayer: (mediaId) => ({
    type: playerActions.SHOW_PLAYER,
    mediaId,
  }),
  hidePlayer: () => ({
    type: playerActions.HIDE_PLAYER,
  }),
  fetchMediaJson: (mediaId, options, cb) => ({
    type: playerActions.FETCH_MEDIA_JSON,
    mediaId,
    options,
    cb
  }),
  fetchPciFlag: (mediaId) => ({
    type: playerActions.FETCH_PII_FLAG,
    mediaId,
  }),
  fetchPciFlagAction: (accountId) => ({
    type: playerActions.FETCH_PII_FLAG_ACTION,
    accountId,
  }),
  changeMediaActionFlag: (accountId, action) => ({
    type: playerActions.CHANGE_MEDIA_ACTION_FLAG,
    accountId,
    action,
  }),
  updatePciFlag: (mediaId, pciFlagAction, lastSelectedPlayer, filters) => ({
    type: playerActions.UPDATE_PII_FLAG,
    mediaId,
    pciFlagAction,
    lastSelectedPlayer,
    filters,
  }),
  handleMinimizePlayer: (isPlayerMinimized) => ({
    type: playerActions.MINIMIZE_PLAYER,
    isPlayerMinimized,
  })
};

export default playerActions;
