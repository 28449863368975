/** @prettier */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import _ from 'lodash';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { showToast } from './toast';

export function timeDifference(time) {
  const givenTime = new Date(time);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => (number > 1 ? 's' : '');
  const number = (num) => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return 'a few seconds ago';
  };
  return getTime();
}

export function sanitizeFloatValue(value) {
  if (Number.isNaN(value) || value === Infinity || value == null) return 0.0;
  if (typeof value === 'number') return value;
  if (typeof value === 'string') {
    const num = parseFloat(value);
    if (num.toString() === value) return num;
  }
  return null;
}

export function isNumeric(str) {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  }
  if (isNumeric(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}

export function arrayFromString(str) {
  return str
    ? str
        .split(',')
        .map((x) => x.trim())
        .filter((x) => x)
    : null;
}

export function getUniqFromData(data = [], prop) {
  return _(data.reduce((result, curr) => [...result, ...curr[prop]], []))
    .map((x) => x.trim())
    .uniq()
    .orderBy((x) => x.toLowerCase())
    .value();
}

export function getUniqPropsFromData(data = []) {
  if (Object.prototype.toString.call(data) !== '[object Array]') return [];
  return !!data && !!data.length ? Object.keys(data.reduce((a, c) => ({ ...a, ...c }), {})) : [];
}

export function getDefaultSort(model) {
  const defaultSort = model ? model.find((x) => x && x.defaultSort) || {} : {};
  const sortDirection = _.get(defaultSort, 'sortDirection', 'asc');
  return [{ field: _.get(defaultSort, 'field', 'updatedDt'), dir: sortDirection }];
}

export function getPrimaryKey(model) {
  const primaryKey = model ? model.find((x) => x.primaryKey) || { field: 'id' } : { field: 'id' };
  return primaryKey.field || 'id';
}

export function objOrFirst(func = () => {}, data) {
  return Array.isArray(data) ? func(data[0]) : func(data);
}

export function hasIntersection(arr1 = [], arr2 = [], caseSensitive = true) {
  const arr1Result = caseSensitive ? arr1 : arr1.map((x) => x.toLowerCase());
  const arr2Result = caseSensitive ? arr2 : arr2.map((x) => x.toLowerCase());
  return _.intersection(arr1Result, arr2Result).length > 0;
}

export function getBaseReducer(model) {
  return {
    sort: getDefaultSort(model),
    pk: getPrimaryKey(model),
    selectedItem: {},
    filters: {},
    view: false,
    init: false,
    importCSV: false,
    data: [],
  };
}

export function nestChildren(arr, parent, childProp = 'children') {
  const result = [];
  arr.forEach((item) => {
    const clonedItem = _.cloneDeep(item);
    if (clonedItem.parent === parent) {
      const children = nestChildren(arr, clonedItem.id, childProp);
      if (children.length) {
        clonedItem[childProp] = children;
      }
      result.push(clonedItem);
    }
  });
  return result;
}

// Takes a string formula (i.e. '(a + b) * c'), and converts it into a nested, space-trimmed array
// example:
//   formulaToArray('(a + b) * c')
// produces:
// [
//    [
//      'a', '+', 'b'
//    ],
//    '*',
//    'c'
// ]
//
// this is the first step in tokenizing the formula, which will enable us to build better UI around the formulas
export function formulaToArray(formula = '') {
  const step1 = formula
    // eslint-disable-next-line
    .split(/([\(\)\+\-\*\/])/)
    .map((x) => x.trim())
    .join(' ')
    .replace(/\)\s\)/g, '))')
    .replace(/\(/g, '[')
    .replace(/\)\s/g, '], ')
    .replace(/\)/g, ']');

  const step2 = `[${step1}]`;

  const step3 = step2
    // eslint-disable-next-line
    .replace(/[^\[\]\,\s]+/g, '"$&"')
    .replace(/" /g, '", ')
    // eslint-disable-next-line
    .replace(/\,[\s]+\]/g, ']');

  return JSON.parse(step3);
}

export const timeIntervals = {
  hour: {
    prev: 'minute',
    prevCount: 60,
    prevTotal: 360,
    timespan: 21600000, // 6 hours
  },
  day: {
    prev: 'hour',
    prevCount: 24,
    prevTotal: 72,
    timespan: 259200000, // 3 days
  },
  week: {
    prev: 'day',
    prevCount: 7,
    prevTotal: 21,
    timespan: 1814400000, // 3 weeks
  },
  month: {
    prev: 'day',
    prevCount: 30,
    prevTotal: 90,
    timespan: 7862400000, // 3 months
  },
  year: {
    prev: 'month',
    prevCount: 12,
    prevTotal: 36,
    timespan: 94670856000, // 3 years
  },
};

export const copyToClipboard = (valueToCopy) => {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = valueToCopy;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  showToast('success', { task: 'Copied!' });
  // toast.success('Copied!', { autoClose: 1100, hideProgressBar: true });
};

export const getNextUniqueNumber = (arr = [], name = '', defaultName) => {
  const names = arr
    .filter((x) => x.name.toLowerCase().indexOf(name.toLowerCase()) === 0)
    .map((x) => x.name.substring(name.length).trim());
  if (names.length <= 1 && name !== defaultName) return names.length * 2;
  const numbers = names.map((x) => parseInt(x, 10)).filter((x) => x);
  return Math.max(...numbers, 0) + 1;
};

export const getUniqueName = (arr, nameProvided, defaultName = 'Query') => {
  const name = nameProvided || defaultName;
  const number = getNextUniqueNumber(arr, name, defaultName);
  return `${name}${number ? ` ${number}` : ''}`;
};

export const searchData = (data = [], filters = {}, searchFields = []) =>
  filters && 'search' in filters
    ? data.filter((x) => searchFields.some((s) => _.toLower(x[s]).indexOf(_.toLower(filters.search)) > -1))
    : data;

export const removeDuplicates = (arr = []) => [...new Set(arr)];

/**
 * Returns object or undefined.
 *
 * @param {object} o object to search from.
 * @param {string} prop property to be used to find.
 * @param {string} v value to be used to find.
 * @return {object | undefined} object or undefined.
 */
export const findByPropertyName = (o, prop, v) => {
  if (!o || !prop || !v) {
    return null;
  }
  if (o[prop] && o[prop].toLowerCase?.() === v.toLowerCase?.()) {
    return o;
  }
  let res;
  for (const [k, val] of Object.entries(o)) {
    if (o.hasOwnProperty(k) && val && typeof val === 'object') {
      res = findByPropertyName(val, prop, v);
      if (res) return res;
    }
  }
  return null;
};
/**
 * AccountId is 12 digits
 * @param {*} str
 */
export const isValidArnRoleString = (str) => !!str.match(/^arn:aws:iam::\d{12}:role\/.+/g);

/**
 * Azure Blob Service URL validation
 * @param {*} url
 */
export const isValidAzureBlobServiceURL = (url) =>
  !!url.match(/^https:\/\/[a-zA-Z0-9\-]+\.blob\.core\.windows\.net(\/.*)?(?<!\s)$/);

export const isValidAzureBlobServiceWithContainerURL = (url) =>
  !!url.match(/^https:\/\/[a-zA-Z0-9\-]+\.blob\.core\.windows\.net\/[a-zA-Z0-9\-]+$/);

export const isValidAzureQueueURL = (url) =>
  !!url.match(/^https:\/\/[a-zA-Z0-9\-]+\.queue\.core\.windows\.net\/[a-zA-Z0-9\-]+$/);

/**
 *
 * @param {function} func
 * @param {number} delay
 */

export function debounce(func, delay = 200) {
  let inDebounce;
  // eslint-disable-next-line func-names
  return function () {
    clearTimeout(inDebounce);
    // eslint-disable-next-line prefer-rest-params
    inDebounce = setTimeout(() => func.apply(this, arguments), delay);
  };
}

export const sortByPropName = (prop) => (a, b) => {
  const nameA = a?.[prop]?.toUpperCase(); // ignore upper and lowercase
  const nameB = b?.[prop]?.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export const sortByPropNameAndType =
  (prop, type = 'string') =>
  (a, b) => {
    switch (type) {
      case 'date':
        if (!a?.[prop] || !b?.[prop]) return null;
        return new Date(a[prop]) - new Date(b[prop]);
      case 'number':
        if (!a?.[prop] || !b?.[prop]) return null;
        return (Number(a[prop]) || 0) - (Number(b[prop]) || 0);
      case 'string':
      default:
        return a?.[prop]?.toString()?.toUpperCase().localeCompare(b?.[prop]?.toString()?.toUpperCase());
    }
  };

export function getTimeFromNow(date) {
  // const time = moment(date).fromNow();
  // return time === 'in a few seconds' ? 'few seconds ago' : time;
  return moment.utc(date).format('YYYY/MM/DD HH:mm')
}

export function getTimeFromNowRelative(date) {
  const time = moment(date).fromNow();
  return time === 'in a few seconds' ? 'few seconds ago' : time;
}

export function rePosition(items = [], sort = true) {
  let count = 1;
  let displayCount = 1;
  if (sort) {
    items.sort((a, b) => a.position - b.position);
  }
  const newItems = items.map((el) => {
    const clonedEl = _.cloneDeep(el);
    clonedEl.position = count++;
    clonedEl.uiNumber = displayCount++;
    if (clonedEl.children && clonedEl.children.length) {
      clonedEl.children = clonedEl.children.map((elc) => {
        const clonedElc = _.cloneDeep(elc);
        clonedElc.position = null;
        clonedElc.uiNumber = null;
        return clonedElc;
      });
    }
    return el;
  });

  return newItems;
}

export function timeDifferenceInSecondsFromNow(time) {
  return moment.duration(moment().diff(time)).asSeconds();
}

export function formatSeconds(duration, format) {
  // 'HH:mm:ss'
  return moment(duration * 1000)
    .utc()
    .format(format);
}

export function toFixedNum(num, fixVal = 2) {
  return parseFloat(num).toFixed(fixVal);
}

export function checkValidDate(str) {
  return moment(str, moment.ISO_8601, true).isValid();
}

export function sanitizeInfinity(input) {
  if (['Infinity', '-Infinity', 'NaN'].some((na) => na === input)) return '-';
  return input;
}

export function formatDateForCharts(str) {
  // NOTE UTC formatted string.
  if (checkValidDate(str)) {
    return moment.utc(str).format('MM/DD/YY');
  }
  return str;
}

export const truncateWithEllipsis = (str = '', max = 10) =>
  str.length > max ? `${str.substring(0, max - 1)}...` : str;

export function exportToJson(objectData = {}, filename = 'export.json') {
  const contentType = 'application/json;charset=utf-8;';
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], { type: contentType });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    a.download = filename;
    a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(objectData))}`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    showToast('success', {
      task: 'Successfully downloaded JSON.',
    });
  }
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * If date is today set to now otherwise set to end of day
 * @param {*} value
 * @returns
 */
export const calculateEndDate = (value) => {
  let endDate;
  const isToday = moment(value).isSame(moment(), 'day');
  if (isToday) {
    endDate = moment(value).utc().toISOString();
  } else {
    endDate = moment(value).utc().endOf('day').toISOString();
  }
  return endDate;
};

export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes}.${seconds < 10 ? '0' : ''}${seconds}`;
}

export function formatDateForCell(date) {
  return moment(date).format('M/D/YYYY h:mm A');
}

export const bulkExportTranscripts = async (medias) => {
  if (!medias) return;
  const zip = new JSZip();

  const resolveTranscripts = async () => {
    for await (const media of medias) {
      const transcript = await Promise.resolve(media.transcript);
      if (!transcript || typeof transcript !== 'string') return;
      const agentName = media.agent || media.callDetails?.agent?.fullName || 'No agent details';
      const callStartTime = (
        moment(media.callDetails?.callStartTime).format('MMM DD, YYYY, hh:mm:ss A') ||
        moment(new Date()).format('MMM DD, YYYY, hh:mm:ss A')
      ).replace(/:/g, '');

      // Formats each line to add a linebreak in between a speaker and their message
      const splitTranscriptByLine = transcript?.split('\n');
      const newLines = splitTranscriptByLine.map((line) => {
        const speakerIndex = line.indexOf(': ');
        const speaker = line.slice(0, speakerIndex);
        const message = line.slice(speakerIndex + 1).trim();
        return [speaker, message];
      });

      const flattenedTranscript = _.flatten(newLines).join('\n');
      zip.file(`${agentName}_${callStartTime}_${media.mediaId}.txt`, flattenedTranscript);
    }
  };
  await resolveTranscripts();

  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'Bulk Transcripts.zip');
  });
};

export const saveBlob = (response, defaultFilename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;

  let filename = defaultFilename;

  // Extract filename from Content-Disposition header if available
  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
    if (filenameMatch && filenameMatch.length === 2) {
      filename = filenameMatch[1];
    }
  }

  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export const isJSON = (str) => {
  if (typeof str !== 'string') return false;

  str = str.trim();

  if (
    str.startsWith('{') && str.endsWith('}') ||
    str.startsWith('[') && str.endsWith(']')
  ) {
    return true;
  }

  return false;
}

export const mergeDeleteRequests = (arr1=[], arr2=[]) => {
  return arr1.map(item1 => {
    const matchingItem2 = arr2.find(item2 => item2.organizationId === item1.accountId);
    
    if (matchingItem2) {
      // Create a new object with the appended `platform` keys from arr2
      const platformData = Object.keys(matchingItem2).reduce((acc, key) => {
        acc[`platform${key.charAt(0).toUpperCase()}${key.slice(1)}`] = matchingItem2[key];
        return acc;
      }, {});
      
      return { ...item1, ...platformData };
    }
    
    return item1; // If no match found, return the item as is
  });
};
export const ensureHttps = (url) => {
  if (url && !url.startsWith("https://")) {
      url = "https://" + url;
  }
  return url;
}

export const exportTranscript = (data, mediaId) => {
  const content = data;
  const blob = new Blob([content], { type: 'text/plain;charset=UTF-8' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${mediaId}.txt`; // File name
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
  showToast('success', {
    task: 'Successfully downloaded.',
  });
}

export const getExtensionFromContentType = (contentType) => {
  const mimeTypes = {
    "audio/mpeg": ".mp3",
    "audio/wav": ".wav",
    "audio/ogg": ".ogg",
    "audio/aac": ".aac",
    "audio/x-wav": ".wav",
    // Add more content types and their corresponding extensions here as needed
  };
  return mimeTypes[contentType] || '';
}

export const downloadMediaUrl = async (url, filename, contentType, cb) => {
  try {
    const extension = getExtensionFromContentType(contentType || "audio/mpeg");
    const hasExtension = new RegExp(`${extension}$`, 'i').test(filename);
    const finalFilename = hasExtension ? filename : `${filename}${extension}`;
    const response = await fetch(url);
    // Check if response is ok (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = finalFilename || `media${extension}`;
    // Append to body
    document.body.appendChild(link);
    // Start download
    link.click();
    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
    cb({ success: true });
  } catch (error) {
    console.error('Download failed:', error);
    cb({ success: false, error });
  }
};

export function formatDateForCellUTC(date) {
  return moment.utc(date).format('M/D/YYYY h:mm A');
}

export function formatMediaLength(ms) {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format to two-digit hours, minutes, and seconds
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  // Display `hh:mm:ss` if there are hours, otherwise `mm:ss`
  return hours > 0 
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes}:${formattedSeconds}`;
};

export function convertToISO8601UTC(date){
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};